import React from 'react';
import {connect} from "react-redux";
import {fetchPitToJoin, joinPit} from "../../actions/pitActions";
import Loading from '../Loading';


class JoinPit extends  React.Component {


    constructor( props) {

        super(props);
        this.state = {
            portfolioName: "",
            pitPassword: ""
        };

        this.changePortfolioName = ev => this.setState({portfolioName: ev.target.value });
        this.changePitPassword= ev => this.setState({pitPassword: ev.target.value });

        this.submitForm = (e) => {
            e.preventDefault();
            this.props.joinPit(this.props.pitToJoin.id, this.state.portfolioName, this.state.pitPassword );
        };
    }

    componentDidMount(){
        this.props.fetchPitToJoin(this.props.match.params.id);
        this.joinPit = this.props.joinPit
    }



    render() {
        if( this.props.fetchingPitToJoin === null || this.props.fetchingPitToJoin  ){
            return (
                <Loading />
            )
        }


        let pit = this.props.pitToJoin;

        if( pit === '' || pit === null){
            return (
                <div className="container">
                       Pit not found
                </div>
            )
        }

        let responseMessage =  this.props.responseMessage;

        if( !responseMessage && this.props.createPitResponse != null && this.props.createPitResponse.messageResponse != null  ){
            responseMessage = this.props.createPitResponse.messageResponse
        }



        let messagePanel = responseMessage !== "" ?
            <div  className={"alert alert-"+responseMessage.alertType}  role="alert">
                {responseMessage.message}
                </div> : "";
        let pitPassword = pit.type === 'PRIVATE' ?
            <li className="list-group-item">
                <div className="form-group">
                    <label htmlFor="pit_password"><strong>Pit Password:</strong></label>
                    <input type="password" name="pitPassword" className="form-control" id="pit_password"
                           required="true" value={this.state.pitPassword} onChange={this.changePitPassword}
                    />
                </div>
            </li> : "";
        let buyInParagraph = pit.buyIn === 0 ?
            (<div>
                <div className="alert alert-primary">
                    <div className="media">
                        <i className="align-self-center far fa-thumbs-up  fa-3x mr-3"/>
                        <p className="media-body">
                            <strong>This pit is purely for fun!</strong><br/>No payouts or dues will be required at the end of
                            the season.
                        </p>
                    </div>
                </div>
            </div>) :
            (<div>
                <div className="form-check">
                    <label className="form-check-label">
                        <input type="checkbox" name="confirmBuyIn" className="form-check-input"
                               value="true" required="true"/>
                        Confirm Buy-in
                    </label>
                </div>
                <div className="alert alert-primary">
                    <div className="media">
                        <i className="align-self-center fas fa-exclamation-triangle fa-3x mr-3"/>
                        <div  className="media-body">
                            <strong>Whoa seems steep!</strong><br/>Think of this as more of an investment. The likelihood of loosing your entire buy-in is extremely low.
                        </div>
                    </div>
                </div>
            </div>);
        return (
            <div className="container mt-4">
                <div className="card">
                    <div className="card-header">
                        <h3>
                            {pit.name}
                        </h3>
                    </div>
                    <div className="card-body">
                        {messagePanel}
                        <form method="post" onSubmit={this.submitForm} className="form">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <strong>Portfolios entered: </strong>
                                    <span>{pit.portfolios.length}</span>
                                </li>
                                <li className="list-group-item">
                                    <div className="form-group">
                                        <label><strong>Buy-in:</strong> <span>{pit.buyIn}</span></label>
                                        {buyInParagraph}
                                    </div>
                                </li>
                                {pitPassword}
                                <li className="list-group-item">
                                    <div className="form-group">
                                        <label htmlFor="portfolio_name"><strong>Name your portfolio:</strong></label>
                                        <input name="portfolioName" className="form-control" id="portfolio_name"
                                               aria-describedby="portfolio_name_help" placeholder="Portfolio Name"
                                               maxLength="50" required="true" value={this.state.portfolioName}
                                            onChange={this.changePortfolioName} />
                                        <small id="portfolioHelp" className="form-text text-muted">
                                            This is the name other people will see.
                                        </small>
                                    </div>
                                    <input type="submit" className="btn btn-success" value="Join Pit"/>
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
                <br/>
            </div>
    )
    }
    }

    const mapStateToProps = state => ({
        account: state.authentication.account,
        fetchingPitToJoin: state.pits.fetchingPitToJoin,
        pitToJoin: state.pits.pitToJoin,
        responseMessage: state.pits.responseMessage,
        createPitResponse: state.pits.createPitResponse
    });

    const mapDispatchToProps = dispatch  => ({
        fetchPitToJoin: (pitId) => dispatch( fetchPitToJoin( pitId) ),
        joinPit:  (pitId, portfolioName, pitPassword) => dispatch( joinPit( pitId, portfolioName, pitPassword ) )

    });



    export default connect(
    mapStateToProps,
    mapDispatchToProps
    )(JoinPit);
