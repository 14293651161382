import {combineReducers} from 'redux';
import pits from './reducers/pits'
import applicationMessage from './reducers/applicationMessage'
import authentication from './reducers/authentication'
import register from './reducers/register'
import leagues from './reducers/leagues'
import leagueSeasonStocks from './reducers/leagueSeasonStocks'
import portfolio from './reducers/portfolios'
import research from './reducers/research'
import stock from './reducers/stock'
import stocks from './reducers/stocks'
import marketTransaction from './reducers/marketTransaction'
import {LOGOUT_SUCCESS} from "./actions/allActions";

const appReducer = combineReducers({
    applicationMessage,
    authentication,
    marketTransaction,
    pits,
    register,
    leagues,
    leagueSeasonStocks,
    portfolio,
    research,
    stock,
    stocks
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_SUCCESS) {
        state = undefined
    }

    return appReducer(state, action)
};

export default rootReducer;
