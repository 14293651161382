import {
    FETCH_STOCKS_AVAILABLE,
    FETCH_STOCKS_AVAILABLE_SUCCESS,
    FETCH_LEAGUE_SEASON_STOCK_SUMMARIES,
    FETCH_LEAGUE_SEASON_STOCK_SUMMARIES_SUCCESS,
    FETCH_LEAGUE_SEASON_STOCK_SUMMARIES_FAIL
} from '../actions/allActions';

const initialState = {
    //todo: We may want to have two sets of stocks here: one for summaries which contain trading info and the other w/ details
    loading: false,
    stockSummaries: undefined
};

export default function stocks(state = initialState, action) {
    switch (action.type) {
        case FETCH_STOCKS_AVAILABLE:
            return {
                ...state,
                loading: true
            };
        case FETCH_STOCKS_AVAILABLE_SUCCESS:
            return {
                ...state,
                loading: false,
                available: action.stocks
            };
        case FETCH_LEAGUE_SEASON_STOCK_SUMMARIES:
            return {
                ...state,
                loading: true,
                stockSummaries: []
            };
        case FETCH_LEAGUE_SEASON_STOCK_SUMMARIES_SUCCESS:
            return {
                ...state,
                loading: false,
                stockSummaries: action.summaries
            };
        case FETCH_LEAGUE_SEASON_STOCK_SUMMARIES_FAIL:
            return {
                ...state,
                loading: false,
                errorMessage: "Error fetching stock summaries"
            };
        default:
            return state;
    }
}
