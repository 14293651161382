import React from 'react'
import { Link } from 'react-router-dom';

import "../css/index.css"
import {connect} from "react-redux";
class IndexPage extends React.Component {

    render() {
        let ssprMessagePanel = "";
        let ssprMessage = this.props.ssprMessage;

        if( ssprMessage !== undefined  && ssprMessage !== "" ){
            ssprMessagePanel =  <div className={"alert alert-"+this.props.ssprMessage.alertType+" alert-dismissible"}
                                     role="alert">{this.props.ssprMessage.message}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
        }
        return(
        <div>
            <div className="jumbotron cover">
                <div className="container">
                    {ssprMessagePanel}
                    <h1>Show them who's boss.</h1><br/>
                    <h3>Buy and sell shares of your favorite teams.</h3>
                    <h3>At the end of the season the most profitable portfolios win!</h3>
                    <br/>
                    <p>
                    {
                        this.props.authentication.isAuthenticated ?
                        <Link className="btn btn-lg btn-success" to="/home" role="button">Manage portfolio</Link>
                            :
                        <Link className="btn btn-lg btn-success" to="/register" role="button">Start trading now</Link>
                    }
                    </p>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <h3><i className="fas fa-folder-open"/>&nbsp;Build your portfolio</h3>
                        <p> Join a public pit, or build one with your friends.
                            Then invest in as many of your favorite teams as your pit allowance can afford.
                        </p>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <h3><i className="fas fa-handshake"/>&nbsp;Make moves all week</h3>
                        <p>You are free to make any number of trades at any time during the course of the week.
                            However, shares of teams involved in a game will be frozen from the start of the game until the result is confirmed.</p>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <h3><i className="fa fa-fw fa-trophy"/>&nbsp;Win the league</h3>
                        <p>At the end of the season if you beat the pit average you will receive a proportional amount of money
                            back, but if you don't you will likewise lose a proportional amount of money.</p>
                    </div>
                </div>
            </div>
        </div>
    )
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    ssprMessage: state.applicationMessage.ssprMessage
});


export default connect(mapStateToProps, null)(IndexPage);