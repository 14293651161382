import axios from 'axios'
import {ERROR_MESSAGE, FETCH_LEAGUES_SUCCESS, FETCH_LEAGUE_SEASON_STOCKS,  FETCH_LEAGUE_SEASON_STOCKS_SUCCESS} from "./allActions"

export function fetchLeagues(data) {
    return {type: FETCH_LEAGUES_SUCCESS, leagues: data}
}

export function getLeagues() {
    return (dispatch) => {
        axios.get('/api/market/leagues')
            .then(response => {
                if (response.status === 200) {
                    dispatch(fetchLeagues(response.data));
                    return response;
                } else {
                    dispatch({type: ERROR_MESSAGE})
                }
            })
    }
}

export function fetchLeagueSeasonStocks(data) {
    return {type: FETCH_LEAGUE_SEASON_STOCKS_SUCCESS, stocks: data}
}

export function getLeagueSeasonStocks(leagueSeason) {
    return (dispatch) => {
        dispatch( {type: FETCH_LEAGUE_SEASON_STOCKS})
        axios.get(`/api/market/stocks/full?leagueSeason=${leagueSeason}`)
            .then(response => {
                if (response.status === 200) {
                    dispatch(fetchLeagueSeasonStocks(response.data));
                    return response;
                } else {
                    dispatch({type: ERROR_MESSAGE});
                }
            });
    }
}
