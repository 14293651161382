import {connect} from 'react-redux';
import * as d3 from 'd3';
import React from 'react';
import {PortfolioCard} from "./PortfolioCard";


class Portfolios extends React.Component {
    constructor( props) {
        super(props);
    }

    componentDidMount(){
        if( this.props.joinedPits === null  ){
            this.fetchJoinedPits()
        }
    }

    render() {

        if( this.props.portfolios === null ){
            return ""
        }


        const activePortfolioMap = this.props.portfolios.length !== 0 ?
            d3.nest()
                .key(function(portfolio){ return portfolio.active ? "active" : "inactive"})
                .object(this.props.portfolios) : [];



        const activePortfolios = activePortfolioMap["active"];
        const inactivePortfolios = activePortfolioMap["inactive"];


        return (
            <div>
                <h2>Your Portfolios</h2>
                <hr/>
                <ul className="nav nav-tabs" id="portfolioTab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="activePortfolio-tab" data-toggle="tab" href="#activePortfolios" role="tab"
                           aria-controls="activePortfolios" aria-selected="true"><i className="fal fa-user-chart"></i> Active <span className="d-none d-sm-inline">Portfolios</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="inactivePortfolio-tab" data-toggle="tab" href="#inactivePortfolios" role="tab"
                           aria-controls="inactivePortfolios" aria-selected="true"><i className="far fa-archive"></i>  Inactive <span className="d-none d-sm-inline">Portfolios</span></a>
                    </li>
                </ul>
                <div className="tab-content" id="portfolioTabContent">
                    <div className="tab-pane fade show active" id="activePortfolios" role="tabpanel"
                         aria-labelledby="activePortfolio-tab">
                        {activePortfolios === undefined ?
                            <div className='alert alert-warning alert-dismissible' role='alert'><i
                                className='fas fa-exclamation-triangle'/> You aren't in any active trading pits.
                                Please join one below.
                                <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            :
                            <div className="row">
                                {activePortfolios.map((p, index) => {
                                    return (
                                        <PortfolioCard portfolio={p}/>
                                    )
                                })
                                }
                            </div>
                        }
                    </div>
                    <div className="tab-pane fade" id="inactivePortfolios" role="tabpanel"
                         aria-labelledby="inactivePortfolio-tab">
                        {inactivePortfolios === undefined ?
                            <div className='alert alert-warning alert-dismissible' role='alert'><i
                                className='fas fa-exclamation-triangle'/> You don't have any closed portfolios.
                                <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            :
                            <div className="row">
                                {inactivePortfolios.map((p, index) => {
                                    return (
                                        <PortfolioCard portfolio={p}/>
                                    )
                                })
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    portfolios: state.portfolio.accountPortfolios,
});

const mapDispatchToProps = dispatch => ({
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Portfolios);