import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as pitActions from '../../actions/pitActions';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';


class AvailablePits extends React.Component {

    constructor( props) {
        super(props);
        this.fetchPitsAvailable= (dispatch) => this.props.pitActions.fetchPitsAvailable(dispatch);

    }


    componentDidMount(){
        if( this.props.pitsAvailable === null ){
            this.fetchPitsAvailable()
        }
    }

    render() {

        let portfolios = this.props.accountPortfolios;

        let inActivePit = false;
        for( let p of portfolios ){
            if( p.active ){
                inActivePit = true;
                break;
            }
        }
        let pitRows =  this.props.fetchingPitsAvailable ? "<tr><td colspan='3'>Searching Fot pits to join.</td></tr>" :
            this.props.pitsAvailable !== null ? this.props.pitsAvailable.map((pit,index) =>
                <tr key={pit.id}>
                    <td> <Link className="btn btn-success btn-sm" to={"/pit/join/"+pit.id}>Join Pit</Link></td>
                    <td>{pit.name}</td>
                    <td> {pit.type === "OPEN" ? <i className="fas fa-users"/> : <i className='fas fa-user-secret'></i>}&nbsp;
                        <span className="d-none d-md-inline">{pit.type === "OPEN" ? <span>Public</span> :<span>Private</span>}</span>
                    </td>
                    <td>{pit.portfolios !== undefined ? pit.portfolios.length : 0 }</td>
                    <td>{pit.buyIn === 0 ? "FREE" : pit.buyIn }</td>
                </tr>
            ) : "";

        return (
            <div >
                { inActivePit ? <a className="btn btn-outline-dark m-2" href="javascript:void()"
                             onClick={function(){ document.getElementById('joinPitDiv').style.display === "none" ?
                                 document.getElementById('joinPitDiv').style.display = "block" :
                                 document.getElementById('joinPitDiv').style.display = "none"; } }>
                    <i className="fas fa-users"/> Join another pit</a> : ""}
                <div id="joinPitDiv" className="" style={inActivePit ? {display: "none"} : {display: "block"}}>
                    <table className="table mt-1">
                        <thead className="thead-dark">
                        <tr>
                            <th/>
                            <th scope="col"> <span className="d-none d-md-inline">Pit </span>Name</th>
                            <th scope="col">Type</th>
                            <th scope="col">Members</th>
                            <th scope="col">Entry</th>
                        </tr>
                        </thead>
                        <tbody>
                        {pitRows}
                        </tbody>
                        <tfoot>
                        <tr>
                            <th colSpan="5">
                                <Link to="/pit/create" className="btn btn-outline-success btn-sm"><i className="far fa-plus-square"/> Create a new pit</Link>
                            </th>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    fetchingPitsAvailable:   state.pits.fetchingPitsAvailable,
    accountPortfolios: state.portfolio.accountPortfolios,
    pitsAvailable: state.pits.pitsAvailable
});

const mapDispatchToProps = dispatch => ({
    pitActions: bindActionCreators(pitActions, dispatch)
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AvailablePits);