import React from 'react';
import {connect} from "react-redux";
import {changePassword} from "../../actions/authenticationActions";

class UpdatePassword extends  React.Component {

    constructor(props){
        super( props );

        this.state = {
            newPassword: '',
            confirmPassword: '',
            currentPassword: ''
        };

        this.submitForm = (e) => {
            e.preventDefault();

            let accountId = this.props.accountId !== null ? this.props.accountId : this.props.match.params.accountId;
            let token = this.props.match.params.t !== undefined ? this.props.match.params.t : "";

            this.props.changePassword( this.state.newPassword, this.state.confirmPassword,
                this.state.currentPassword, token, accountId );
        };

        this.updateNewPassword = ev => this.setState({newPassword: ev.target.value });
        this.updateConfirmPassword= ev => this.setState({confirmPassword: ev.target.value });
        this.updateCurrentPassword= ev => this.setState({currentPassword: ev.target.value });

    }



    render() {
        let token = this.props.match.params.t;
        let accountId = this.props.accountId !== null ? this.props.accountId : this.props.match.params.accountId;

        let statusPanel = "";


        if( this.props.statusMessage !== "" ){
            statusPanel = <div className={"alert alert-"+this.props.statusMessage.alertType}>{this.props.statusMessage.message}</div>
        }

        if( accountId === null ){
            if( this.props.loading ){
                return ( "Loading");
            }else{
                return <div className="container mt-4"><div className="alert alert-danger"><strong>Error</strong> Please start over.</div></div>
            }

        }


        let currentCredentials = token != null ?
            <input type="hidden" value={token} name="t" id="token" /> :
            <div className="form-group">
                <label htmlFor="currentPassword">Current Password</label>
                <div>
                    <input type="password" id="currentPassword" name="currentPassword" className="form-control"  onChange={this.updateCurrentPassword}/>
                </div>
                <hr/>
            </div>;

        return (
            <div className="container mt-4">
                <div className="card rounded-0">
                    <div className="card-header">
                        <h3 className="mb-0">Update Password</h3>
                    </div>
                    <div className="card-body">
                        {statusPanel}
                        <form className="form form-horizontal" method="post" onSubmit={this.submitForm}>
                            {currentCredentials}
                            <div className="form-group">
                                <label htmlFor="password">New Password</label>
                                <div>
                                    <input type="password" name="password" id="password" className="form-control"
                                           autoComplete="off" onChange={this.updateNewPassword}/>
                                    <span className="help-block"/>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="passwordVerify">Verify Password</label>
                                <div>
                                    <input type="password" name="passwordVerify" id="passwordVerify"
                                           className="form-control" autoComplete="off" onChange={this.updateConfirmPassword}/>
                                </div>
                            </div>
                            <input type="submit" className="btn btn-primary float-right mb-1" value="Update Password"/>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    accountId: state.authentication.account !== null ? state.authentication.account.id : null,
    loading: state.authentication.loading,
    statusMessage: state.applicationMessage.passwordMessage
});

const mapDispatchToProps = (dispatch, state) => ({
    changePassword: (newPassword, confirmPassword, currentPassword, token, accountId) =>
        dispatch( changePassword(newPassword,  confirmPassword, currentPassword, token, accountId) )
    // fetchPortfolio: ( id ) =>
    //     dispatch( getFullPortfolio( id ) ),
    // clearMessage: () =>
    //     dispatch( {type: CLEAR_STATUS_MESSAGE} )
});


export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);