import React from 'react';
import {connect} from "react-redux";
import {createPit} from "../../actions/pitActions";
import { Link } from 'react-router-dom';

class CreatePit extends  React.Component {


    constructor( props) {

        super(props);
        this.state = {
            leagueSeason: "",
            name: "",
            open: true,
            type: "PRIVATE",
            password: "",
            buyIn: "0",
            cashAllowance: 100,
            payoutMultiplier: 1,
            pitDuration: "FULL_SEASON",
            closeDate: ""
        };


        this.submitForm = (e) => {
            e.preventDefault();

            let newPit =  { ...this.state };
            this.props.createPit( newPit );
        };
    }

    componentDidMount(){
    }

    handleInputChange = (e) => {
        let value = e.target.value;
        let inputName = e.target.name;
        this.setState({[inputName]: value});
    };

    render() {
        // let messagePanel =  this.props.responseMessage !== "" ?
        {/*<div  className={"alert alert-"+this.props.responseMessage.alertType}  role="alert">*/}
        {/*{this.props.responseMessage.message}*/}
        {/*</div> : "";*/}
        // let { pitType, pitName } = this.state;

        return (
            <div className="container mt-4">
                <div className="row">
                    <div className="col-md-6 offset-md-3 col-xs-12">
                        <h1 className="text-xs-center">Create a pit</h1>
                        <hr/>
                        {/*{errorPanel}*/}
                        <form onSubmit={this.submitForm } className="form form-horizontal " >
                            <div className="form-group">
                                <label htmlFor="name"><strong>Name</strong></label>
                                <input type="text" onChange={this.handleInputChange} id="name" name="name"
                                   value={this.state.name} className={"form-control"} aria-describedby="pitNameHelp"/>
                                <small id="pitNameHelp" className="form-text text-muted">Used to identify your pit</small>
                            </div>
                            <div className="form-group">
                                <label><strong>League</strong></label>
                                <br className="clearfix"/>
                                <div className="form-check form-check-inline">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="radio" name="leagueSeason"
                                               value="MLB_2019_REGULAR" required="true"
                                               onChange={this.handleInputChange} /> MLB
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="radio" name="leagueSeason"
                                               value="NFL_2019_REGULAR" required="true"
                                               onChange={this.handleInputChange} /> NFL
                                    </label>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="pitDuration"><strong>Pit Duration</strong></label>
                                <br className="clearfix"/>
                                <div className="form-check form-check-inline">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="radio" name="pitDuration"
                                               value="FULL_SEASON" required="true"
                                               onChange={this.handleInputChange} /> Full Season
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="radio" name="pitDuration"
                                               value="PARTIAL_SEASON" required="true"
                                               onChange={this.handleInputChange}  /> Partial Season
                                    </label>
                                </div>
                            </div>
                            <div className="form-group" id="closeDateDiv" hidden={this.state.pitDuration === "FULL_SEASON"}>
                                <div className="form-group">
                                    <label htmlFor="cloaseDate"><strong>Closing Date</strong></label>
                                    <input type="text" onChange={this.handleInputChange} id="closeDate" name="closeDate" placeholder="MM/DD/YYYY"
                                           value={this.state.cloaseDate} className={"form-control"} aria-describedby="closeDateHelp"/>
                                    <small id="closeDateHelp" className="form-text text-muted">The date the pit will be closed.</small>
                                </div>
                            </div>
                            <div className="form-group">
                                <label><strong>Type</strong></label>
                                <br className="clearfix"/>
                                <div className="form-check form-check-inline">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="radio" name="type"
                                               value="PRIVATE" required="true" checked={this.state.type === "PRIVATE"}
                                               onChange={this.handleInputChange} /> Private
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="radio" name="type"
                                               value="OPEN" required="true" checked={this.state.type === "OPEN"}
                                               onChange={this.handleInputChange} /> Public
                                    </label>
                                </div>
                            </div>
                            <div hidden={this.state.type === "OPEN"}>
                                <div className="form-group">
                                    <label htmlFor={"password"}><strong>Pit Password</strong></label>
                                    <input type="text"
                                           onChange={this.handleInputChange}
                                           id="password"
                                           name="password"
                                           value={this.state.password}
                                           className={"form-control"}
                                           aria-describedby="pitPasswordHelp"/>
                                    <small id="pitPasswordHelp" className="form-text text-muted">This is the password people will use to join this pit.</small>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="buy-in"><strong>Buy In</strong></label>
                                <input type="number" onChange={this.handleInputChange} min={0} id="buy-in" name="buyIn" value={this.state.buyIn} className={"form-control"}  aria-describedby="buyInHelp"/>
                                <small id="buyInHelp" className="form-text text-muted">Enter 0 for FREE pit.</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="cashAllowance"><strong>Starting Cash</strong></label>
                                <input type="number" onChange={this.handleInputChange} min={10} id="cashAllowance" name="cashAllowance" value={this.state.cashAllowance} className={"form-control"}  aria-describedby="cashAllowanceHelp"/>
                                <small id="cashAllowanceHelp" className="form-text text-muted">The amount of cash new portfolios will start with.</small>
                            </div>
                            {/*<div className="form-group">*/}
                            {/*    <label htmlFor="payoutMultiplier"><strong>Payout Multiplier</strong></label>*/}
                            {/*    <input type="number" onChange={this.handleInputChange} min={1} max={20} id="payoutMultiplier" name="payoutMultiplier" value={this.state.payoutMultiplier} className={"form-control"}  aria-describedby="payoutMultiplierHelp"/>*/}
                            {/*    /!*todo: make this  helper text dynamic*!/*/}
                            {/*    <small id="payoutMultiplierCashHelp" className="form-text text-muted">With a payout multiplier of 5, pits that are valued 20% below the pit average will hit rock bottom.<br/>Portfolios 5% below the pit average will hit rock bottom with a multiplier of 20!</small>*/}
                            {/*</div>*/}
                            <input type="submit" className="btn btn-success" value="Save"/>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    // account: state.authentication.account,
    // fetchingPitToJoin: state.pits.fetchingPitToJoin,
    // pitToJoin: state.pits.pitToJoin,
    // responseMessage: state.pits.responseMessage
});

const mapDispatchToProps = dispatch  => ({
    createPit: (newPit) => dispatch( createPit(newPit))
    // fetchPitToJoin: (pitId) => dispatch( fetchPitToJoin( pitId) ),
    // joinPit:  (pitId, portfolioName, pitPassword) => dispatch( joinPit( pitId, portfolioName, pitPassword ) )

});



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreatePit);
