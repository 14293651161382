import _ from 'lodash';
import React from 'react';
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import Loading from '../Loading'
import {fetchPitDetails} from '../../actions/pitActions'
import * as txt from '../../utils/text'
import {formatDateDay} from '../../utils/text'
import ValueHierarchy from "./ValueHierarchy";
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import classnames from 'classnames';


class PitSummary extends  React.Component {

    constructor(props) {
        super(props);

        this.toggle = _.bind(this.toggle, this);

        this.state = {
            activeTab: '1'
        };

    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidMount(){
        this.props.fetchPitDetails(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.debouncedWindowResizeListener);
    }

    render() {
        const pitDetails = this.props.pitDetails;

        if (pitDetails === null) {
            return <Loading />;
        }

        const parts = pitDetails.leagueSeason.split('_');
        const league = _.first(parts);
        const season = _.tail(parts).join('-');

        const pitAverage = pitDetails.averageValue;



        const accountPortfolioIds = this.props.accountPortfolios.map( function(p){ return p.id});

        return (
            <div className="container mt-4">
                <h2>{pitDetails.name}</h2>
                <h6 className="text-muted">{`${league}, ${season}`}</h6>
                <hr/>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="card overview price">
                            <div className="card-body">
                                <h5>
                                    {txt.format(txt.formats.currency, pitDetails.totalValue)} <small className="text-muted">USD</small>
                                </h5>
                                <table className="table table-sm text-muted mb-0 mt-4" >
                                    <tbody>
                                    <tr>
                                        <td>Members</td>
                                        <td className="text-right">
                                            {pitDetails.portfolioSummaries.length}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Volume (Lifetime)
                                            <i className="fas fa-info-circle tooltip" data-toggle="popover"
                                               data-content="The number of shares traded (purchased or sold) across the lifetime of the pit."
                                               data-trigger="hover" /></td>
                                        <td className="text-right">{pitDetails.tradeVolume}</td>
                                    </tr>
                                    <tr>
                                        <td>Avg Portfolio value
                                            <i className="fas fa-info-circle tooltip" data-toggle="popover"
                                               data-content="The average portfolio total value (including cash)."
                                               data-trigger="hover"/>
                                        </td>
                                        <td className="text-right">
                                            <span>${pitDetails.averageValue}</span>
                                            <small> USD</small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Pit Close Date</td>
                                        <td className="text-right">
                                            {formatDateDay(pitDetails.closeDate)}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="card recentTransactions">
                            <div className="card-body">
                                <h5 className="card-title">Latest Transactions</h5>
                                <div className="card-text">
                                    <table className="table table-sm table-striped">
                                        <tbody>
                                        {
                                            pitDetails.allTransactions &&  pitDetails.allTransactions.length === 0 ?
                                                <tr><td>There are no  transactions.</td></tr> :
                                                _.take( pitDetails.allTransactions, 5).map( function(transaction, index){
                                                    return <tr>
                                                        <td><small>{txt.formatDateDay(transaction.date)}</small></td>
                                                        <td>{transaction.portfolioName}</td>
                                                        <td>{transaction.action}</td>
                                                        <td>{transaction.count} &times; {transaction.shortName}</td>
                                                        <td>{txt.format( txt.formats.currency, transaction.total)} </td>
                                                    </tr>
                                                })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '1' })}
                                 onClick={() => { this.toggle('1'); }}
                        >
                            <h5 className={"pointer"}>Standings</h5>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            id={"holdingsTab"}
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggle('2');  window.dispatchEvent(new Event('resize'));  }}
                        >
                            <h5 className={"pointer"}>Holdings</h5>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            id={"holdingsTab"}
                            className={classnames({ active: this.state.activeTab === '3' })}
                            onClick={() => { this.toggle('3'); }}
                        >
                            <h5 className={"pointer"}>Transactions</h5>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">

                        <table className="table table-sm">
                            <thead className="thead-dark">
                            <tr>
                                <th className="d-none d-md-table-cell">#</th>
                                <th>Portfolio</th>
                                <th className="text-right">Value</th>
                                <th className="text-right d-none d-md-table-cell">vs. Initial</th>
                                <th className="text-right">vs. Pit Average</th>
                                <th className="text-right">Payout</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                pitDetails.portfolioSummaries.map( function(portfolio, index){
                                    let own = false;
                                    if( accountPortfolioIds.includes(portfolio.id) ){
                                        own = true;
                                    }
                                    return(
                                        <tr key={portfolio.id}>
                                            <td className=" d-none d-md-block">{index + 1}</td>
                                            <td>
                                                <Link to={"/portfolio/view/"+portfolio.id}>{portfolio.portfolioName}</Link>
                                                {own ? <span className='badge badge-success ml-3'>You</span> : ""}
                                            </td>
                                            <td className="text-right">{txt.format(txt.formats.currency, portfolio.totalValue)}</td>
                                            <td className={`text-right ${txt.getRelativeTextClass(portfolio.portfolioGain)} d-none d-md-block`}>{txt.format(txt.formats.absoluteChange, portfolio.portfolioGain)}</td>
                                            <td className={`text-right ${txt.getRelativeTextClass(portfolio.relativePercentGain)}`}>{portfolio.relativePercentGain}%</td>
                                            <td className={`text-right ${txt.getRelativeTextClass(portfolio.payout)}`}>{txt.format(txt.formats.currency, portfolio.payout)}</td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </TabPane>
                    <TabPane tabId="2">
                        <ValueHierarchy />
                    </TabPane>
                    <TabPane tabId="3">
                        <p className={"mt-3"}>
                            <table className={"table table-striped"}>
                                <tbody>
                                {
                                    pitDetails.allTransactions  ?
                                    pitDetails.allTransactions.map( function(transaction, index){
                                        return (<tr>
                                            <td><small>{txt.formatDateDay(transaction.date)}</small></td>
                                            <td>{transaction.portfolioName}</td>
                                            <td>{transaction.action}</td>
                                            <td>{transaction.count} &times; {transaction.shortName}</td>
                                            <td>{txt.format(txt.formats.currency, transaction.total)} </td>
                                        </tr>)
                                    }) : <tr></tr>
                                }
                                </tbody>
                            </table>
                        </p>
                    </TabPane>
                </TabContent>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    accountPortfolios: state.portfolio.accountPortfolios,
    pitDetails: state.pits.pitDetails
});

const mapDispatchToProps = (dispatch, state) => ({
    fetchPitDetails: (pitId) =>{
        dispatch( fetchPitDetails(pitId));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PitSummary);
