import { Link } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router'

import googleLogo from '../img/google-logo.png'
import fbLogo  from '../img/fb-logo.png'

import {
    UPDATE_FIELD_AUTH,
    LOGIN_PAGE_UNLOADED, REDIRECT_PATH_SET
} from '../actions/allActions';

import {login } from '../actions/authenticationActions';


const ErrorPanel = ({messageKey}) => (
    <div className="alert alert-danger">
        {messageKey}
    </div>
);

class Login extends React.Component {
    constructor( props) {
        super( props );

        this.state = {
            rememberMe: false
        };

        this.changeUsername = ev => this.props.onChangeUsername(ev.target.value);
        this.changePassword = ev => this.props.onChangePassword(ev.target.value);
        this.toggleRememberMe = ev => this.setState({rememberMe: !this.state.rememberMe});
        this.submitForm = (username, password, rememberMe, redirectPath ) => ev => {
            ev.preventDefault();
            this.props.onSubmit(username, password, rememberMe, redirectPath);
        };
    }


    render() {
        if( this.props.account !== null && this.props.isAuthenticated ){
            this.props.forwardToHome();
        }
        const username = this.props.username ? this.props.username : "";
        const password = this.props.password ? this.props.password : "";

        const testHref = window.location.href;

        const {errorMessage} = this.props;
        const errorPanel = errorMessage ? <ErrorPanel messageKey={errorMessage.message}/> : null;
        return (
            <div className="auth-page mt-2">
                <div className="container page">
                    <div className="row">
                        <div className="col-md-6 offset-md-3 col-xs-12">
                            <h1 className="text-xs-center ">Sign In</h1>
                            <p>Don't want to create an account?</p>
                            <Link to="/oauth2/google">
                                <button className="btn btn-outline-primary">
                                    <img src={googleLogo} className="social-logo google-logo" alt="Google"/> Continue with Google
                                </button>
                            </Link>
                            {/*<Link to="/oauth2/facebook" className="float-right">*/}
                            {/*    <button className="btn btn-outline-primary" disabled>*/}
                            {/*        <img src={fbLogo} className="social-logo fb-logo" alt="Google"/> Continue with Facebook*/}
                            {/*    </button>*/}
                            {/*</Link>*/}
                            <hr/>
                            {errorPanel}
                            <form onSubmit={this.submitForm(username, password, this.state.rememberMe, this.props.redirectPath )}>
                                <fieldset>
                                    <fieldset className="form-group">
                                        <input
                                            className="form-control form-control-lg"
                                            type="text"
                                            placeholder="Username"
                                            name="username"
                                            value={username}
                                            onChange={this.changeUsername} />
                                    </fieldset>
                                    <fieldset className="form-group">
                                        <input
                                            className="form-control form-control-lg"
                                            type="password"
                                            placeholder="Password"
                                            name="password"
                                            value={password}
                                            onChange={this.changePassword} />
                                    </fieldset>
                                    <button
                                        className="btn btn-primary float-xs-right"
                                        type="submit"
                                        disabled={this.props.inProgress}>
                                        Sign in
                                    </button>
                                    <Link to="/account/requestPasswordReset" className="float-right ml-2">
                                        <button
                                            className="btn btn-outline-primary">
                                            Forgot Password?
                                        </button>
                                    </Link>
                                    <Link to="/register" className="float-right ml-2">
                                        <button
                                            className="btn btn-outline-primary">
                                            Create Account
                                        </button>
                                    </Link>
                                </fieldset>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    account: state.authentication.account,
    isAuthenticated: state.authentication.isAuthenticated,
    username: state.authentication.username,
    password: state.authentication.password,
    errorMessage: state.authentication.errorMessage,
    redirectPath: state.authentication.redirectPath
});

const mapDispatchToProps = (dispatch, state) => ({
    onChangeUsername: value =>
        dispatch({ type: UPDATE_FIELD_AUTH, key: 'username', value }),
    onChangePassword: value =>
        dispatch({ type: UPDATE_FIELD_AUTH, key: 'password', value }),
    onSubmit: (username, password, rememberMe, redirectPath ) =>{
        dispatch(login(username.trim(), password, rememberMe, redirectPath))
    },
    onUnload: () =>
        dispatch({ type: LOGIN_PAGE_UNLOADED }),
    forwardToHome: () =>{
        dispatch( push('/home'))
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(Login);
