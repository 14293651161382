import React from 'react';
import {connect} from "react-redux";
import {updatePortfolioName} from "../../actions/portfolioActions";
import _ from 'lodash'
import * as txt from '../../utils/text'


const $ = window.$;

 class PortfolioSummary extends React.Component {

    componentDidUpdate(prevProps) {
         $('#nameForm').hide();
    }


    render(){
        let portfolio = this.props.portfolio;
        let portfolioGain = portfolio.totalValue - portfolio.initialInvestment;
        let ownPortfolio = this.props.accountOwn;
        let dividendsEarned = portfolio.dividends;
console.log( _.sum(_.map(dividendsEarned, function(d) {return  d.total})))
        let totalDividends = dividendsEarned.length > 0 ? _.sum( _.map(dividendsEarned, function(d) {return  d.total}) ) : 0;
        console.log(totalDividends )
        let submitForm = (e) => {
            e.preventDefault();
            this.props.updatePortfolioName( portfolio.id, $('#newName').val() )
        };


        return (
            <div className="card mt-2">
                <div className="card-header">
                    <h5 style={{display: 'inline',  verticalAlign: 'middle'}}>{portfolio.portfolioName}</h5>
                    {ownPortfolio ? <span onClick={function(){$('#nameForm').toggle()} } className="btn btn-light btn-sm ml-1"><i className="far fa-edit"></i></span> : ""}
                    {ownPortfolio ?
                        <div style={{display: "none"}} id="nameForm">
                            <form className="form-inline" onSubmit={submitForm}>
                                <input type="text" className="form-control" id="newName" required/>
                                <input type="submit" className="btn btn-success ml-1" value="Save" />
                            </form>
                        </div>
                        : ""}

                </div>
                <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        <span>Portfolio value:</span>
                        <span>{txt.format(txt.formats.currency, portfolio.totalValue)}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        Portfolio Gain
                        <span className={txt.getRelativeTextClass(portfolioGain)}>
                            {txt.format(txt.formats.absoluteChange, portfolioGain)}
                        </span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        Total Dividends Earned
                        <span>{txt.format(txt.formats.currency, totalDividends)}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        Cash
                        <span>{txt.format(txt.formats.currency, portfolio.cash)}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        Transactions
                        <span>
                            { portfolio.transactions ? <span> {portfolio.transactions.length}
                                <i className="fas fa-history fa-xs ml-2 pointer" onClick={() => {$('#transactionList').toggle();}}/></span> : 0 }
                        </span>
                    </li>
                    <li id="transactionList" className="list-group-item justify-content-between align-items-center" style={{borderTop: "0px;", display: "none"}}>
                       <table className="table table-hover table-sm">
                           <thead>
                                <tr>
                                    <th>Stock</th>
                                    <th>Shares</th>
                                    <th>Action</th>
                                    <th>Date</th>
                                </tr>
                           </thead>
                           <tbody>
                            {
                                portfolio.transactions ?
                                    portfolio.transactions.map((transaction,index) => {
                                              return  <tr>
                                                  <td>{transaction.stock.team.name}</td>
                                                  <td>{transaction.count}</td>
                                                  <td>{transaction.action}</td>
                                                  <td>{txt.formatDate(transaction.date)}</td>

                                              </tr>
                                        }
                                     )
                                    :
                                    ""
                            }
                           </tbody>
                       </table>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        Pit Rank
                        <span>{portfolio.pitRank}/{portfolio.pitSize}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        Gain vs Pit
                        <span className={txt.getRelativeTextClass(portfolio.relativePercentGain)}>{portfolio.relativePercentGain}%</span>
                    </li>
                </ul>
            </div>
        )
    }
}
const mapStateToProps = state => ({
});

const mapDispatchToProps = (dispatch, state) => ({

    updatePortfolioName: ( id, newName) =>
        dispatch( updatePortfolioName( id, newName ) )

    // handleMarketTransactionChange: (e) => {
    //     let field = e.target.name;
    //     let value = e.target.value;
    //     dispatch( {type: UPDATE_MARKET_TRANSACTION_FIELD, key: field, value})
    // }
    });

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioSummary);
