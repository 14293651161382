import {SET_MARKET_TRANSACTION} from "../../actions/allActions";
import {connect} from "react-redux";
import React from "react";
import * as d3 from "d3";
import _ from "lodash";

class ValueHierarchy extends React.Component {
    constructor(props) {
        super(props);

        this.chartRef = React.createRef();

        this.onWindowResize = _.bind(this.onWindowResize, this);

        this.debouncedWindowResizeListener = _.debounce(this.onWindowResize, 150);
        this.renderChart();

    }

    componentDidMount() {
        window.addEventListener('resize', this.debouncedWindowResizeListener, false);
        this.renderChart();
    }

    componentDidUpdate(prevProps, prevState) {
        this.renderChart();
    }

    renderChart() {
        const chart = this.chartRef.current;
        console.log( this.props.pitDetails.valueHierarchy )
        if (chart) {
            chart.innerHTML = "";
            drawTreemap(chart, this.props.pitDetails.valueHierarchy);
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.debouncedWindowResizeListener);
    }



    onWindowResize() {
        this.renderChart();
    }

    render() {
        const chartStyle = {
            background: '#ddd',
            height: '400px',
        };
        return (<div>
            <div ref={this.chartRef} className="mb-4 mt-4 treemap" style={chartStyle}/>
        </div>)
    }
}

function name(d) {
    if (d.parent) {
        return name(d.parent) + " > " + d.data.name;
    }

    return d.data.name;
}

function drawTreemap(container, hierarchy) {
    var margin = {top: 20, right: 0, bottom: 0, left: 0};

    var width = container.clientWidth;
    var height = container.clientHeight - margin.top - margin.bottom;

    var formatCurrency = d3.format("$.2f");
    var transitioning;
    var backupChildren;

    function text(text) {
        text.attr("x", function(d) { return d.x0 + 6; })
            .attr("y", function(d) { return d.y0 + 6; });
    }

    function rect(rect) {
        rect.attr("x", function(d) { return d.x0; })
            .attr("y", function(d) { return d.y0; })
            .attr("width", function(d) { return d.x1 - d.x0; })
            .attr("height", function(d) { return d.y1 - d.y0; });
    }

    var svg = d3.select(container)
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.bottom + margin.top)
        .style("margin-left", -margin.left + "px")
        .style("margin-right", -margin.right + "px")
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        .style("shape-rendering", "crispEdges");

    var grandparent = svg.append("g")
        .attr("class", "grandparent");

    grandparent.append("rect")
        .attr("y", -margin.top)
        .attr("width", width)
        .attr("height", margin.top);

    grandparent.append("text")
        .attr("x", 6)
        .attr("y", 6 - margin.top)
        .attr("dy", ".65em");

    function getTreemap(w, h) {
        return d3.treemap()
            .size([w, h])
            .round(false)
            .paddingInner(1);
    }

    var treemap = getTreemap(width, height);

    var root = d3.hierarchy(hierarchy)
        .eachBefore(function (d, depth) {
            return depth ? null : d._children;
        })
        .sum(function (d) { return d.value; })
        .sort(function (a, b) {
            return b.height - a.height || b.value - a.value;
        });

    var childValues = _.map(root.children, function (child) {
        return child.value;
    });

    var colorBasis =["#f7fcf5", "#e5f5e0", "#c7e9c0", "#a1d99b", "#74c476", "#41ab5d", "#238b45", "#006d2c", "#00441b"];
    var colorScale = d3.scaleSequential(d3.interpolateRgbBasis(colorBasis))
        .domain([d3.min(childValues), d3.max(childValues)]);

    function initialize(root) {
        root.x0 = root.y0 = 0;
        root.x1 = width;
        root.y1 = height;
        root.depth = 0;
    }

    function accumulate(d) {
        d._children = d.children;

        if (d._children) {
            d.value = _.reduce(d.children, function (sum, v) {
                return sum + accumulate(v);
            }, 0);
        }

        return d.value;
    }

    function getNode(d) {
        var tm = getTreemap(d.x1 - d.x0, d.y1 - d.y0);
        var node = d3.hierarchy(d.data)
            .sum(function (d) { return d.value; })
            .sort(function (a, b) { return b.height - a.height || b.value - a.value; });
        tm(node);
        return node;
    }

    function layout(d) {
        if (d._children) {
            var node = getNode(d);
            _.forEach(node.children, function (c, i) {
                var z = d._children[i];
                z.x0 = c.x0;
                z.y0 = c.y0;
                z.x1 = c.x1;
                z.y1 = c.y1;
                z.parent = d;
                layout(z)
            });
        }
    }

    function display(d) {
        grandparent
            .datum(d.parent)
            .on("click", _.partial(transition, 'out'))
            .select("text")
            .text(name(d));

        var g1 = svg.insert("g", ".grandparent")
            .datum(d)
            .attr("class", "depth");

        var g = g1.selectAll("g")
            .data(d._children)
            .enter().append("g");

        g.filter(function (d) { return d._children; })
            .classed("children", true)
            .on("click", _.partial(transition, 'in'));

        g.selectAll(".child")
            .data(function (d) { return d._children || [d]; })
            .enter().append("rect")
            .attr("class", "child")
            .call(rect);

        g.append("clipPath")
            .attr("id", function (d, i) { return 'cp-' + i; })
            .append("rect").call(rect);

        g.append("rect")
            .attr("class", "parent")
            .style("fill", function (d) {
                var color = _.get(d, ['data', 'metadata', 'teamColor']);

                if (!color) {
                    return colorScale(d.value);
                }

                var rgb = d3.rgb('#' + color);
                rgb.opacity = 0.25;
                return rgb.toString();
            })
            .call(rect)
            .append("title")
            .text(function (d) { return formatCurrency(d.value); });

        g.append("text")
            .attr("clip-path", function (d, i) { return "url(#cp-" + i + ")"; })
            .attr("dy", ".75em")
            .text(function (d) { return d.data.name; })
            .call(text);

        function transition(mode, d) {
            if (transitioning || !d) {
                return;
            }

            transitioning = true;

            var g2 = display(d);
            var t1 = g1.transition().duration(750);
            var t2 = g2.transition().duration(750);

            svg.style("shape-rendering", null);

            svg.selectAll(".depth").sort(function (a, b) {
                return a.depth - b.depth;
            });

            g2.selectAll("text").style("fill-opacity", 0);

            if (mode === 'in') {
                var cp = _.defaults({
                    x0: 0,
                    x1: width,
                    y0: 0,
                    y1: height
                }, d);
                d._restore = true;
                backupChildren = _.cloneDeep(d._children);
                layout(cp);
            } else {
                var child = _.find(d.children, {_restore: true});
                _.forEach(child._children, function (c, i) {
                    c.x0 = backupChildren[i].x0;
                    c.x1 = backupChildren[i].x1;
                    c.y0 = backupChildren[i].y0;
                    c.y1 = backupChildren[i].y1;
                });
                delete child._restore;
            }

            t1.selectAll("text").call(text).style("fill-opacity", 0);
            t2.selectAll("text").call(text).style("fill-opacity", 1);

            t1.selectAll("rect").call(rect);
            t2.selectAll("rect").call(rect);

            t1.remove().on("end", function () {
                svg.style("shape-rendering", "crispEdges");
                transitioning = false;
            });
        }

        return g;
    }

    initialize(root);
    accumulate(root);
    layout(root);
    treemap(root);
    display(root);
}


const mapStateToProps = state => ({
    pitDetails: state.pits.pitDetails
});

const mapDispatchToProps = (dispatch, state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ValueHierarchy);