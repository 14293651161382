import {
    FETCH_LEAGUE_SEASON_STOCKS,
    FETCH_LEAGUE_SEASON_STOCKS_SUCCESS
} from '../actions/allActions';

const initialState = {
    loading: false,
    stockEntries: undefined
};

export default function leagueSeasonStocks(state = initialState, action) {
    switch (action.type) {
        case FETCH_LEAGUE_SEASON_STOCKS:
            return {
                ...state,
                stockEntries: [],
                loading: true
            };
        case FETCH_LEAGUE_SEASON_STOCKS_SUCCESS:
            return {
                ...state,
                loading: false,
                stockEntries: action.stocks
            };
        default:
            return state;
    }
}
