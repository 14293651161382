import {
    FETCH_PORTFOLIO, FETCH_PORTFOLIO_SUCCESS,
    PROCESS_MARKET_TRANSACTION_SUCCESS,
    JOIN_PIT_SUCCESS, CREATE_PIT_SUCCESS,
    UPDATE_PORTFOLIO_NAME_SUCCESS,
    FETCH_ACCOUNT_PORTFOLIOS, FETCH_ACCOUNT_PORTFOLIOS_SUCCESS, FETCH_ACCOUNT_PORTFOLIOS_FAIL,

} from '../actions/allActions';

const initialState = {
    loading: true,
    accountPortfolios: null,
    portfolioDetails: null,
    joinPitMessage: ""
};

export default function portfolio(state = initialState, action) {

    switch (action.type) {
        case FETCH_PORTFOLIO:
            return {
                ...state,
                loading: true,
            };
        case FETCH_PORTFOLIO_SUCCESS:
            return {
                ...state,
                loading: false,
                portfolioDetails: action.portfolio
            };
        case FETCH_ACCOUNT_PORTFOLIOS:
            return {
                ...state,
                loading: true,
                accountPortfolios: []
            };
        case FETCH_ACCOUNT_PORTFOLIOS_SUCCESS:
            return {
                ...state,
                loading: false,
                accountPortfolios: action.portfolios
            };
        case FETCH_ACCOUNT_PORTFOLIOS_FAIL:
            return {
                ...state,
                loading: false
            };
        case JOIN_PIT_SUCCESS:
            return {
                ...state,
            };
        case UPDATE_PORTFOLIO_NAME_SUCCESS:
            return {
                ...state,
                loading: false,
                portfolioDetails: action.portfolio,
                accountPortfolios: state.accountPortfolios.map( (p) => {
                                if( p.id === action.portfolio.id){
                                    p.portfolioName = action.portfolio.portfolioName
                                }
                                return p;
                            })
            };
        default:
            return state;
    }
}