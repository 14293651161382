import * as d3 from 'd3'
import _ from 'lodash'
import queryString from 'query-string'
import React from 'react'
import {connect} from "react-redux"
import {getLeagues, getLeagueSeasonStocks} from '../actions/researchActions'
import * as LeagueSeason from '../utils/LeagueSeason'
import {CLEAR_STOCK, FETCH_LEAGUE_SEASON_STOCKS, UPDATE_RESEARCH_FIELD} from "../actions/allActions";
import {getLeagueFromLeagueSeason} from "../utils/LeagueSeason";

const $ = window.$;

function drawMultiLineChart(container, yAxisLabel, data) {
    var width = container.clientWidth;
    var height = Math.min(window.innerHeight * .6, 600);

    var svg = d3.select(container)
        .append('svg')
        .attr('width', width)
        .attr('height', height);

    var margin = {top: 20, right: 80, bottom: 30, left: 50};
    var chartWidth = width - margin.left - margin.right;
    var chartHeight = height - margin.top - margin.bottom;

    var g = svg.append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    var x = d3.scaleTime().rangeRound([0, chartWidth]);

    var dates = _.reduce(data, function (memo, datum) {
        return _.reduce(datum.values, function (memo, datum) {
            var date = datum.date;

            if (memo.indexOf(date) >= 0) {
                return memo;
            }

            return memo.concat(date);
        }, memo);
    }, []);

    x.domain(d3.extent(dates));

    var y = d3.scaleLinear().range([chartHeight, 0]);
    y.domain([
        d3.min(data, function (datum) {
            return d3.min(datum.values, function (d) {
                return d.value;
            });
        }) * 0.9,
        d3.max(data, function (datum) {
            return d3.max(datum.values, function (d) {
                return d.value;
            })
        }) * 1.1
    ]);

    var line = d3.line()
        .x(function (d) { return x(d.date); })
        .y(function (d) { return y(d.value); });

    g.append('g')
        .attr('class', 'axis x')
        .attr('transform', 'translate(0,' + chartHeight + ')')
        .call(d3.axisBottom(x));

    g.append('g')
        .attr('class', 'axis y')
        .call(d3.axisLeft(y))
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 6)
        .attr('dy', '0.71em')
        .attr('fill', '#000')
        .text(yAxisLabel);

    var value = g.selectAll('.value')
        .data(data)
        .enter().append('g')
        .attr('class', 'value');

    value.append('path')
        .attr('class', 'line')
        .attr('d', function (d) { return line(d.values); })
        .attr('fill', 'none')
        .attr('stroke', function (d) { return d.color; });

    var points = value.selectAll('.points')
        .data(data)
        .enter().append('g')
        .attr('class', 'points');

    points.selectAll('circle')
        .data(function (d) {
            return _.map(d.values, function (datum) {
                return _.defaults({
                    color: d.color
                }, datum);
            });
        })
        .enter().append('circle')
        .attr('r', 4)
        .attr('fill', function (d) { return d.color; })
        .attr('cx', function (d) { return x(d.date); })
        .attr('cy', function (d) { return y(d.value); })
        .attr('title', function (d) { return d.title; })
        .attr('data-content', function (d) { return d.popover; })
        .attr('data-delay', 125)
        .attr('data-html', 'true')
        .attr('data-toggle', 'popover')
        .attr('data-trigger', 'hover')
        .style('cursor', 'pointer');

    value.append('text')
        .datum(function (d) { return {label: d.label, value: _.last(d.values)} })
        .attr('transform', function (d) { return 'translate(' + x(d.value.date) + ',' + y(d.value.value) + ')'; })
        .attr('x', 3.15)
        .attr('dy', '0.35em')
        .style('font', '10px sans-serif')
        .text(function (d) { return d.label; });

    var popovers = container.querySelectorAll('[data-toggle="popover"]');
    _.forEach(popovers, function (popover) {
        return $(popover).popover({
            container: 'body'
        });
    });
}

var popoverTemplate = [
    '<ul class="list-group list-group-flush">',
    '<li class="list-group-item d-flex justify-content-between align-items-center">',
    '<%- game.awayTeam.city %>&nbsp;<%- game.awayTeam.name %>',
    '<span class="<%- badgeClassNames(\'away\') %>">',
    '<%- game.awayScore %>',
    '</span>',
    '</li>',
    '<li class="list-group-item d-flex justify-content-between align-items-center">',
    '<%- game.homeTeam.city %>&nbsp;<%- game.homeTeam.name %>',
    '<span class="<%- badgeClassNames(\'home\') %>">',
    '<%- game.homeScore %>',
    '</span>',
    '</li>',
    '</ul>',
].join('');

function getPopoverTemplate(history) {
    if (!history.game) {
        return '';
    }

    var template = _.template(popoverTemplate);
    return template(
        _.defaults({
            badgeClassNames: function (side) {
                var classes = ['badge'];
                if (side === 'away') {
                    if (this.game.awayScore > this.game.homeScore) {
                        classes.push('badge-primary');
                    }
                } else {
                    if (this.game.homeScore > this.game.awayScore) {
                        classes.push('badge-primary');
                    }
                }

                return classes.join(' ');
            }
        }, history)
    );
}

function prepareDataFromElo(stocks) {
    var parseTime = d3.timeParse("%Y-%m-%d %H:%M");
    return _.map(stocks, function (stock) {
        var team = stock.team;
        return {
            color: '#' + team.colorPrimary,
            label: team.shortName,
            values: _.map(stock.priceHistory, function (pricePoint) {
                var title;

                if (pricePoint.game) {
                    title = [
                        pricePoint.game.startDate,
                        '&nbsp;',
                        pricePoint.game.startTime
                    ].join('');
                }

                return {
                    date: parseTime(pricePoint.priceDate),
                    value: pricePoint.price,
                    title: title,
                    popover: getPopoverTemplate(pricePoint)
                };
            })
        };
    });
}

class Research extends React.Component {

    constructor(props) {
        super(props);



        this.chartRef = React.createRef();

        this.onLeagueChange = _.bind(this.onLeagueChange, this);
        this.onSeasonChange = _.bind(this.onSeasonChange, this);
        this.onStockInput = _.bind(this.onStockInput, this);
        this.onStockClick = _.bind(this.onStockClick, this);

        this.onWindowResize = _.bind(this.onWindowResize, this);
        this.debouncedWindowResizeListener = _.debounce(this.onWindowResize, 15);

        // NOTE attempt to pull out league season from the route
        const leagueSeasonParam = _.get(this.props, ['match', 'params', 'leagueSeason']);
        if (leagueSeasonParam) {
            const league = getLeagueFromLeagueSeason(leagueSeasonParam);

            // NOTE attempt to pull out stocks from the query string
            let {stocks} = queryString.parse(this.props.location.search);
            if( stocks !== undefined ){
                if(!Array.isArray(stocks))
                    stocks = !!stocks ? [stocks] : [];

                let stockIds = _.map( stocks, (s) => {
                    return parseInt(s);
                });
                if( !_.isEmpty(stockIds) ){
                    this.props.updateResearchField('selected_stocks', stockIds );
                }
            }
        }

    }

    onLeagueChange(e) {
        const league = e.target.value;
        this.props.updateResearchField('league', league );
        const leagueSeasons = this.props.leagues[league]
        if( leagueSeasons ){
            this.props.updateResearchField('league_seasons', leagueSeasons );
            this.props.updateResearchField('league_season', leagueSeasons[0] );
        }


    }

    onSeasonChange(e) {
        const season = e.target.value
        this.props.updateResearchField('league_season', season );
        this.props.fetchLeagueSeasonStocks( season );
        this.props.clearStock()
    }

    onStockInput(e) {
        const code = e.target.value;
        const stock = _.find( this.props.stocks.stockEntries, (c) => {
            return (c.team.shortName === code);
        });

        if (stock) {
            this.props.updateResearchField('selected_stocks', _.union(_.get(this.props.research, 'selected_stocks'), [stock.id]) );
            e.target.value = null;
        }
    }

    onStockClick(e) {
        const id = parseInt(e.currentTarget.getAttribute('data-id'), 10);
        this.props.updateResearchField('selected_stocks',
            _.without(_.get(this.props.research, 'selected_stocks'), id))
    }

    getStocks() {
        let stocks;
        if (!this.props.stocks.loading) {
            const selectedStocks = _.get(this.props.research, 'selected_stocks');
            stocks = _.map(this.props.stocks.stockEntries, (stock) => {
                const team = stock.team;
                const name = `${team.city} ${team.name}`;
                const disabled = _.includes(selectedStocks, stock.id);
                return <option key={stock.id} value={team.shortName} disabled={disabled}>{name}</option>
            });
        }
        return stocks;
    }

    getStockBadges() {
        let badges;
        if (!this.props.stocks.loading) {
            const selectedStocks = _.get(this.props.research, 'selected_stocks');

            badges = _.map(selectedStocks, (memo ) => {
                const stock = _.find(this.props.stocks.stockEntries, (c) => {
                    return (_.get(c, 'id') === memo);
                });

                // NOTE if the stock is not valid we should ignore it
                if (!stock) {
                    return;
                }

                const color = `#${stock.team.colorPrimary}`;
                const buttonStyle = {
                    backgroundColor: color,
                    borderColor: color,
                    color: '#fff',
                    margin: '3px 3px 3px 0'
                };

                const spanStyle = {
                    color: 'inherit',
                    float: 'none'
                };

                return (
                    <button key={stock.id} data-id={stock.id} className="btn" type="button" style={buttonStyle} onClick={this.onStockClick}>
                        <span className="close" aria-label="Close" style={spanStyle}>&times;</span> {stock.team.shortName}
                    </button>
                );
            });
        }
        return badges;
    }

    render() {

        let leagueSeasons = this.props.research.league_seasons;
        let leagues = _.map(Object.keys(this.props.leagues), (league) =>{
            let isSelected = this.props.research.league === league ? 'active' : '';
            return <button value={league} className={"btn btn-outline-dark " + isSelected} onClick={this.onLeagueChange}>
                <i className={LeagueSeason.getIconFromLeague(league)} /><br/>
                {league}</button>
        });

        let leagueSeasonOptions =_.map(leagueSeasons, (ls) => {
            let isSelected = ls === this.props.research.league_season;
            return <option selected={isSelected}>{ls}</option>
        });



        const stockListTitle = this.props.stocks.loading ? 'Loading...' : 'Type a city, team or stock...';

        const stocks = this.getStocks();
        const stockBadges = this.getStockBadges();

        return (
            <div className="container-fluid mt-4">
                <div className="row">
                    <div className="col-lg-3 col-md-4">
                        <form onSubmit="return false;">
                            <div className="form-group">
                                <label htmlFor="league">League</label><br/>
                                <div className="btn-group btn-group-toggle" data-toggle="buttons" >
                                    {leagues}
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="season">Season</label>
                                <select className="form-control" name="season" onChange={this.onSeasonChange}>{leagueSeasonOptions}</select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="stocks">Stocks</label>
                                <input type="text" className="form-control" id="stocks" name="stocks" autoComplete={"off"} placeholder={stockListTitle} list="stockList" onInput={this.onStockInput}/>
                                <datalist id="stockList" title={stockListTitle}>{stocks}</datalist>
                            </div>
                            <div id="stock-selections" className="form-group">{stockBadges}</div>
                        </form>
                    </div>
                    <div ref={this.chartRef} id="chart" className="col-lg-9 col-md-8"></div>
                </div>
            </div>
        )
    }

    getStocksFromState() {
        // NOTE if we fail to find the stock in props filter it out
        return _.reduce(_.get(this.props.research, 'selected_stocks'), (memo, id) => {
            const stock = _.find(this.props.stocks.stockEntries, (c) => c.id === id);
            return (stock ? memo.concat(stock) : memo);
        }, []);
    }

    renderChart() {
        const chart = this.chartRef.current;

        const popovers = chart.querySelectorAll('[data-toggle="popover"]');
        _.forEach(popovers, function (popover) {
            return $(popover).popover('dispose');
        });

        chart.innerHTML = '';

        const stocks = this.getStocksFromState();
        drawMultiLineChart(chart, 'Stock Price', prepareDataFromElo(stocks));
    }

    onWindowResize() {
        this.renderChart();
    }

    componentDidMount() {
        // NOTE attempt to pull out league season from the route
        const leagueSeasonParam = _.get(this.props, ['match', 'params', 'leagueSeason']);
        if (leagueSeasonParam) {
            const league = getLeagueFromLeagueSeason(leagueSeasonParam)
            this.props.updateResearchField('league_season', leagueSeasonParam );
        }


        // NOTE attempt to pull out stocks from the query string
        let {stocks} = queryString.parse(this.props.location.search);
        if( stocks !== undefined ){
            if(!Array.isArray(stocks))
                stocks = !!stocks ? [stocks] : [];

            let stockIds = _.map( stocks, (s) => {
                return parseInt(s);
            });
            if( !_.isEmpty(stockIds) ){
                this.props.updateResearchField('selected_stocks', stockIds)
            }
        }


        window.addEventListener('resize', this.debouncedWindowResizeListener, false);

        const leagueSeason = this.props.research.league_season;
        if( this.props.research.league ){
            const leagueSeasons = this.props.leagues[this.props.research.league]
            this.props.updateResearchField('league_seasons', leagueSeasons );
        }

        if( leagueSeason ){
            this.props.fetchLeagueSeasonStocks(leagueSeason);
        }

        this.renderChart()

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.debouncedWindowResizeListener);
    }

    componentDidUpdate(prevProps, prevState) {
        const changed = _.some(['league', 'league_season', 'selected_stocks'], (key) => {
            return _.get(prevProps.research, key) !== _.get(this.props.research, key);
        });

        // if (changed || this.props.stocks.loading) {
        //     const leagueSeason = this.props.research.league_season;
        //     if( leagueSeason ){
        //         this.props.fetchLeagueSeasonStocks(leagueSeason);
        //     }
        // }

        if( this.props.research.league_season !== prevProps.research.league_season ){
            this.props.fetchLeagueSeasonStocks(this.props.research.league_season)
        }

        // NOTE we need to call the method here, because on initial load the
        // state could be set from query string, and after data is loaded we
        // would fail to render without taking that into account (that being
        // that we loaded the data, and can render the stock)
        if (this.getStocksFromState() !== _.get(prevProps.research, 'selected_stocks')) {
            this.renderChart();
        }
    }
}

const mapStateToProps = state => ({
    leagues: state.leagues,
    research: state.research,
    stocks: state.leagueSeasonStocks
});

const mapDispatchToProps = (dispatch, state) => ({
    fetchLeagueSeasonStocks: (leagueSeason) => dispatch(getLeagueSeasonStocks(leagueSeason)),
    updateResearchField: (field, value) => {
        dispatch( {type: UPDATE_RESEARCH_FIELD, key: field, value})
    },
    clearStock: () => {dispatch({type: CLEAR_STOCK})}
});

export default connect(mapStateToProps, mapDispatchToProps)(Research);
