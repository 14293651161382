import {
    PROCESS_MARKET_TRANSACTION,
    PROCESS_MARKET_TRANSACTION_FAIL,
    PROCESS_MARKET_TRANSACTION_SUCCESS,
    UPDATE_MARKET_TRANSACTION_FIELD,
    SET_MARKET_TRANSACTION, CLEAR_STATUS_MESSAGE
} from "../actions/allActions";

const initialState = {
        stockId: 0,
        action: "BUY",
        shares: 0,
        transactionMessage: "",
        processing: false,
        highlight: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_MARKET_TRANSACTION_FIELD:
            return{
                ...state,
                highlight: false,
                processing: false,
                [action.key]: action.value
            };
        case SET_MARKET_TRANSACTION:
            return{
                ...action.marketTransaction,
                transactionMessage: "",
                processing: false,
                highlight: true

            };
        case PROCESS_MARKET_TRANSACTION:
            return {
                ...state,
                transactionMessage: "",
                processing: true
            };
        case PROCESS_MARKET_TRANSACTION_SUCCESS:
            return {
                transactionMessage: action.transactionMessage,
                stockId: 0,
                action: "BUY",
                shares: 0,
                processing: false,
                highlight: true
            };
        case PROCESS_MARKET_TRANSACTION_FAIL:
            return {
                ...state,
                highlight: false,
                processing: false,
                transactionMessage: action.transactionMessage
            };
        case CLEAR_STATUS_MESSAGE:
            return {
                ...state,
                transactionMessage: "",
                highlight: false

            };
        default:
            return state;

    }
}