import * as d3 from 'd3';
import * as moment from 'moment';

export const formats = {
    roundedPercent: '.0%',
    absoluteChange: '+,.3f',
    currency: '$,.2f'
};

export function format(str, val) {
    return d3.format(str)(val);
}
export function getRelativeTextClass(val) {
    if (val > 0) {
        return 'text-success';
    }

    if (val < 0) {
        return 'text-danger';
    }

    return 'text-dark';
}


export function formatDate( value ){
    return d3.timeFormat( "%m/%d/%Y  %I:%M %p")(  d3.isoParse(value)  );
}

export function formatDateDay( value ){
    return d3.timeFormat( "%m/%d/%Y")(  d3.isoParse(value)  );
}

export function formatTimeDurationReadable( date){
    return moment( date ).fromNow()
}






