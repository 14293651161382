import React from 'react';
import {connect} from "react-redux";
import {fetchStocksAvailable} from "../../actions/stockActions";
import {processMarketTransaction} from "../../actions/portfolioActions";
import {PROCESS_MARKET_TRANSACTION, UPDATE_MARKET_TRANSACTION_FIELD} from "../../actions/allActions";
import * as txt from '../../utils/text'
import _ from "lodash";
import Link from "react-router-dom/es/Link";

class BuySellShares extends React.Component {


    scrollToDomRef = () => {
        const node = this.buySell.current;
        // const domNode = ReactDOM.findDOMNode();
        if( node !== null ){
            node.scrollIntoView()
        }
    };

    constructor( props) {
        super(props);
        this.fetchStocksAvailable = (dispatch) => this.props.fetchStocksAvailable(this.props.portfolio.leagueSeason);
        this.submitMarketTransaction = (dispatch) => this.props.submitMarketTransaction(this.props.portfolio.id, this.props.marketTransaction);

        this.buySell = React.createRef();

        this.onStockInput = _.bind(this.onStockInput, this);
        this.onStockClick = _.bind(this.onStockClick, this);

    }

    componentDidMount(){
        this.fetchStocksAvailable( this.props.portfolio.leagueSeason )
    }

    componentDidUpdate(prevProps){
        if( this.props.portfolio.leagueSeason !== prevProps.portfolio.leagueSeason ){
          this.props.fetchStocksAvailable(this.props.portfolio.leagueSeason);
        }
    }

    onStockInput(e) {
        const code = e.target.value;
        const stock = _.find( this.props.stocks.available, (c) => {
            return (c.team.shortName === code);
        });

        if (stock) {
            let ev = {
                target: {
                    name: "stockId",
                    value: stock.id
                }
            };
            this.props.handleMarketTransactionChange( ev )
        }
    }

    getSelectedStockPanel( stock ){
        if( stock === undefined ){
            return ""
        }
        return (<div className={"mt-1"}>
            {this.getStockBadge( stock )}
            <Link to={"/stocks/"+stock.id} className={"btn btn-outline-secondary btn-sm"}><i className={"far fa-calendar-alt mr-1"}/> View Schedule</Link>
        </div>)
    }

    onStockClick(e) {
        let ev = {
            target: {
                name: "stockId",
                value: undefined
            }
        };
        this.props.handleMarketTransactionChange( ev )
        document.getElementById("stocks").value='';
    }

    getStockBadge( stock ) {
        const color = `#${stock.team.colorPrimary}`;
        const buttonStyle = {
            backgroundColor: color,
            borderColor: color,
            color: '#fff',
            margin: '3px 3px 3px 0'
        };

        const spanStyle = {
            color: 'inherit',
            float: 'none'
        };

        return (
            <button key={stock.id} data-id={stock.id} className="btn btn-sm" type="button" style={buttonStyle} onClick={this.onStockClick}>
                <span className="close" aria-label="Close" style={spanStyle}>&times;</span> {stock.team.name} @{ txt.format(txt.formats.currency, stock.currentPrice)}
            </button>
        );
    }

    render(){
        let portfolio = this.props.portfolio;
        let stocks = this.props.stocks.available;
        let marketTransaction = this.props.marketTransaction;


        if(  portfolio.loading === true ){
            return("")
        }

        const stock = _.find( stocks, (s) => {
            return (s.id === marketTransaction.stockId);
        });



        let emptyPortfolio = (portfolio.positions.length === 0 && portfolio.transactions.length === 0);
        let noSharesMessage = emptyPortfolio ?
            <div  className="alert alert-primary mb-2 mt-2" role="alert">
                <i className='fas fa-exclamation-triangle'/> We noticed you don&#39;t have any shares. You have ${portfolio.cash} to buy shares below.
            </div>: "";


        let stockPrice = stock ? stock.currentPrice : "0.00";
        let stockName = stock ? stock.team.name : "Team";



        let transactionMessage = marketTransaction.transactionMessage !== "" ?
            <div className={"alert alert-"+marketTransaction.transactionMessage.alertType } role="alert" >
                {marketTransaction.transactionMessage.message}
            </div> : "";

        if( marketTransaction.highlight ){
            this.scrollToDomRef()
        }




        let transactionFee,transactionFeeTotal, transactionTotal, transactionGrandTotal = 0;
        transactionTotal = stockPrice * marketTransaction.shares;


        if( marketTransaction.action === "BUY" ){
            transactionFee = portfolio.transactionFeeBuy;
            transactionFeeTotal = transactionFee * transactionTotal;
            transactionGrandTotal = transactionTotal + transactionFeeTotal;
        }else{
            transactionFee = portfolio.transactionFeeSell;
            transactionFeeTotal = transactionFee * transactionTotal;
            transactionGrandTotal = transactionTotal - transactionFeeTotal;
        }

        let stockListTitle = this.props.stocks.loading ? 'Loading...' : 'Type a city, team or stock...';

        let stockInputValue = stock ? stock.team.shortName : undefined;

        const stocksAvailable = this.props.stocks.available;

        const stocksDataList = stocksAvailable !== undefined ?stocksAvailable.map( function(stock){
            const team = stock.team;
            const name = `${team.city} ${team.name}`;
            const price = stock.openForTrade ? " @" + txt.format(txt.formats.currency, stock.currentPrice) : "  CLOSED";
            let nameAndPrice = name + price;
            const disabled = stock.openForTrade;
            return (<option key={stock.id} value={team.shortName}>{nameAndPrice}</option>)
        }) : (<option>Loading</option>);

        const stockDetails = this.getSelectedStockPanel( stock );

        return (
            <div className={marketTransaction.highlight || emptyPortfolio ? "card mt-3  highlight-success" : "card mt-3  "} ref={this.buySell}>
                <div className="card-header">
                    <h5>Buy / Sell Shares</h5>
                </div>
                <div className="card-body">
                    {noSharesMessage}
                    {transactionMessage}
                    <form onSubmit={ (e) =>{e.preventDefault(); this.submitMarketTransaction()}}>
                        <input type="hidden" name="portfolio.id" value={portfolio.id}/>
                        <div className="form-group ">
                            <label htmlFor="stocks"><strong>Stock</strong></label>
                            <input type="text" autoComplete={"off"} className="form-control" id="stocks" name="stocks" placeholder={stockListTitle} list="stockList" onInput={this.onStockInput} />
                            <datalist id="stockList" title={stockListTitle}>{stocksDataList}</datalist>
                            {stockDetails}
                        </div>
                        <fieldset className="form-group">
                            <label><strong>Action</strong></label>
                            <br className="clearfix"/>
                            <div className="form-check form-check-inline">
                                <label className="form-check-label">
                                    <input className="form-check-input" type="radio" name="action"
                                           value="BUY" required="true" checked={marketTransaction.action  === "BUY"}
                                           onChange={this.props.handleMarketTransactionChange} /> Buy
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <label className="form-check-label">
                                    <input className="form-check-input" type="radio" name="action"
                                           value="SELL" required="true"
                                           checked={marketTransaction.action  === "SELL"}
                                           onChange={this.props.handleMarketTransactionChange} /> Sell
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="count"><strong>Shares</strong></label>
                                <input className="form-control col-4" type="number" name="shares" min="0"
                                       max="9999" value={marketTransaction.shares} required="true"
                                       onChange={this.props.handleMarketTransactionChange} />
                            </div>
                            {/*<span><strong>Transaction</strong></span>*/}
                            <div className="pl-md-4 pr-md-4">
                                <table className="table table-sm">
                                    <tr className="border-top-none">
                                        <td id="stockPrice" className="text-right" style={{width: "100px"}}>{txt.format(txt.formats.currency, stockPrice)}</td>
                                        <td id="stockName" className="text-muted">{stockName}</td>
                                    </tr>
                                    <tr className="border-bottom-thick">
                                        <td id="shareCount" className="text-right">{marketTransaction.shares}</td>
                                        <td className="text-muted">Shares</td>
                                    </tr>
                                    <tr  className={transactionFee === 0 ? 'd-none' : '' }>
                                        <td id="feeTotal" className={ transactionFeeTotal > 0 ? 'text-danger text-right' : 'text-right'}>
                                            {txt.format(txt.formats.currency,transactionFeeTotal)}
                                        </td>
                                        <td className="text-muted">Transaction Fee: {txt.format(txt.formats.roundedPercent, transactionFee)} * {txt.format(txt.formats.currency,transactionTotal)} </td>
                                    </tr>
                                    <tr>
                                        <td id="transactionTotal" className="text-right">{txt.format(txt.formats.currency,transactionGrandTotal)}</td>
                                        <td className="text-muted">Total</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right">
                                        </td>
                                        <td>
                                            <small className="text-muted"> {txt.format(txt.formats.currency, portfolio.cash)} Available cash </small>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <input type="submit" className="btn btn-success"/>
                        </fieldset>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    portfolio: state.portfolio.portfolioDetails,
    stocks: state.stocks,
    marketTransaction: state.marketTransaction
});

const mapDispatchToProps = (dispatch, state) => ({
    fetchStocksAvailable: ( leagueSeason ) =>
        dispatch( fetchStocksAvailable( leagueSeason ) ),
    submitMarketTransaction: ( portfolioId, marketTransaction ) => {
        document.getElementById("stocks").value = "";
        dispatch( {type: PROCESS_MARKET_TRANSACTION});
        dispatch(processMarketTransaction(portfolioId, marketTransaction))
    },
    handleMarketTransactionChange: (e) => {
        let field = e.target.name;
        let value = e.target.value;
        dispatch( {type: UPDATE_MARKET_TRANSACTION_FIELD, key: field, value})
    }

});


export default connect(mapStateToProps, mapDispatchToProps)(BuySellShares);
