import {CLEAR_STOCK, FETCH_STOCK_SUCCESS, UPDATE_RESEARCH_FIELD} from "../actions/allActions";
import {getLeagueFromLeagueSeason} from "../utils/LeagueSeason";

const initialState = {
    league: 'NFL',
    league_season: 'NFL_2019_REGULAR',
    league_seasons: [],
    selected_stock: '',
    selected_stocks: []
};


export default function research(state = initialState, action) {
    switch (action.type) {
        case UPDATE_RESEARCH_FIELD:
                return{
                    ...state,
                    [action.key]: action.value
                };
        case CLEAR_STOCK:
            return {
                selected_stock: '',
                selected_stocks: [],
                ...state
            };
        case FETCH_STOCK_SUCCESS:
            const stock = action.stock;
            return {
                ...state,
                league: getLeagueFromLeagueSeason(stock.league),
                league_season: stock.league,
                selected_stock: stock.id
            };
        default:
            return state;
    }
}
