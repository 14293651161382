import {
    ERROR_MESSAGE,
    FETCH_STOCK_SUCCESS,
    FETCH_STOCKS_AVAILABLE,
    FETCH_STOCKS_AVAILABLE_SUCCESS,
    FETCH_STOCKS_AVAILABLE_FAIL,
    FETCH_LEAGUE_SEASON_STOCK_SUMMARIES, FETCH_LEAGUE_SEASON_STOCK_SUMMARIES_SUCCESS, FETCH_STOCK
} from "./allActions";
import axios from "axios/index";
import {fetchLeagueSeasonStocks} from "./researchActions";

export function fetchStocksAvailable( leagueSeason ){
    return (dispatch) =>
    axios.get('/api/market/stocks/open', {
        params: {
            leagueSeason: leagueSeason
        }
    }).then( response => {
        dispatch({type: FETCH_STOCKS_AVAILABLE});
        if(response.status === 200){
            dispatch( fetchStocksAvailableSuccess( response.data))
        }
        return response;
    }).catch( err => {
        if( err.response !== null && err.response.data !== null ){
            dispatch({type: FETCH_STOCKS_AVAILABLE_FAIL, errorMessage: err.response.data.message})
        }else{
            dispatch({type: ERROR_MESSAGE, err })
        }
    });
}

function fetchStocksAvailableSuccess( data ){
    return { type: FETCH_STOCKS_AVAILABLE_SUCCESS, stocks: data }
}

function fetchStockSuccess(data) {
    return {type: FETCH_STOCK_SUCCESS, stock: data}
}

export function fetchStock(id) {
    return (dispatch) => {
        dispatch({type: FETCH_STOCK} )
        axios.get('/api/market/stocks', {
            params: {
                id: id
            }
        })
        .then(response => {
            if (response.status === 200) {
                dispatch(fetchStockSuccess(response.data));
                return response;
            } else {
                dispatch({type: ERROR_MESSAGE})
            }
        });
    }
}

export function fetchLeagueSeasonStockSummaries(leagueSeason) {
    return (dispatch) => {
        dispatch( {type: FETCH_LEAGUE_SEASON_STOCK_SUMMARIES})
        axios.get(`/api/market/stocks/summaries?leagueSeason=${leagueSeason}`)
            .then(response => {
                if (response.status === 200) {
                    dispatch( {type : FETCH_LEAGUE_SEASON_STOCK_SUMMARIES_SUCCESS, summaries: response.data});
                    return response;
                } else {
                    dispatch({type: ERROR_MESSAGE});
                }
            });
    }
}
