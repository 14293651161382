import {
    CLEAR_STOCK,
    FETCH_STOCK,
    FETCH_STOCK_SUCCESS
} from '../actions/allActions';

const initialState = {
    loading: false,
    stockEntry: undefined
};

export default function stock(state = initialState, action) {
    switch (action.type) {
        case FETCH_STOCK:
            return {
                ...state,
                loading: true,
                stockEntry: undefined
            };
        case FETCH_STOCK_SUCCESS:
            return {
                ...state,
                loading: false,
                stockEntry: action.stock
            };
        case CLEAR_STOCK:
            return {
                ...state,
                stockEntry: undefined
            };
        default:
            return state;
    }
}
