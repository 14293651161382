import React from 'react';
import {connect} from "react-redux";
import { Link } from 'react-router-dom'

import {register} from "../actions/registerActions";


const ErrorPanel = ({messageKey}) => (
    <p className="error-panel">
        <div className="alert alert-danger">{messageKey}</div>
    </p>
);

const LabeledInput = (props) => (
    <div className="form-group">
        <label htmlFor={props.id}>{props.label}</label>
        <div>
            <input className="form-control" {...props}/>
            {/*<div className="help-block" th:if="${#fields.hasErrors('firstName')}" th:errors="*{firstName}"></div>*/}
        </div>
    </div>
);

class RegistrationForm extends React.Component {


    state = {
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        password: "",
        passwordVerify: "",
        errorMessage: ""
    };

    handleInputChange = (e) => {
        let value = e.target.value;
        let inputName = e.target.name;
        this.setState({[inputName]: value});
    };

    render() {
        const {errorMessage} = this.props;
        const errorPanel = errorMessage ? <ErrorPanel messageKey={errorMessage}/> : "";
        return (
            <div className="registrationPage">
                <div className="container mt-2">
                    <div className="row">
                        <div className="col-md-6 offset-md-3 col-xs-12">
                            <h1 className="text-xs-center">Create Account</h1>
                            Already have an account? <Link to="/login">Login</Link>
                            {errorPanel}
                            <form onSubmit={this.submitForm } className="form form-horizontal " noValidate>
                                <LabeledInput onChange={this.handleInputChange} type="text" label="First Name" id="first-name" name="firstName" required/>
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                                <LabeledInput onChange={this.handleInputChange} type="text" label="Last Name" id="last-name" name="lastName" />
                                <LabeledInput onChange={this.handleInputChange} type="text" label="Username" id="username" name="username" required/>
                                <LabeledInput onChange={this.handleInputChange} type="text" label="Email Address" id="email" name="email" required/>
                                <hr/>
                                <LabeledInput onChange={this.handleInputChange} type="password" label="Password" id="password" name="password" required/>
                                <LabeledInput onChange={this.handleInputChange} type="password" label="Verify Password" id="verify-password" name="passwordVerify" required/>
                                <button
                                    className="btn btn-lg btn-primary pull-xs-right"
                                    type="submit"
                                    disabled={this.props.inProgress}>
                                    Sign up
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    submitForm = (e) => {
        e.preventDefault();
        const { firstName, lastName, username, email, password, passwordVerify } = this.state;
        const { register } = this.props;
        register( firstName, lastName, username, email, password, passwordVerify );
    }
}

export default connect(
    state => (
        {
            isAuthenticated: state.authentication.isAuthenticated,
            errorMessage: state.register.errorMessage
        }
    ),
    {register}
)(RegistrationForm);
