import {
    FETCH_LEAGUES,
    FETCH_LEAGUES_SUCCESS
} from '../actions/allActions';

const initialState = {
    NFL: [ "NFL_2019_REGULAR", "NFL_2018_REGULAR", "NFL_2017_REGULAR",  "NFL_2016_REGULAR"],
    MLB: ["MLB_2019_REGULAR","MLB_2018_REGULAR", "MLB_2017_REGULAR",   "MLB_2016_REGULAR" ]
};

export default function leagues(state = initialState, action) {
    // switch (action.type) {
    //     case FETCH_LEAGUES:
    //         return {
    //             ...state,
    //             loading: true,
    //             entries: []
    //         };
    //     case FETCH_LEAGUES_SUCCESS:
    //         return {
    //             ...state,
    //             loading: false,
    //             entries: action.leagues
    //         };
    //     default:
    //         return state;
    // }
    return state;
}
