import React from 'react';
import {connect} from "react-redux";
import {requestSSPR} from "../../actions/authenticationActions";

class RequestSSPR  extends  React.Component {
    constructor(props){
        super(props);

        this.state = {
            email: ""
        };

        this.submitForm = (e) => {
            e.preventDefault();
            this.props.requestPasswordReset(this.state.email);
        };

        this.updateEmail = ev => this.setState({email: ev.target.value });

    }

    render() {
        let statusPanel = "";


        console.log( "this.props.ssprMessage");
        console.log( this.props.ssprMessage);
        if( this.props.ssprMessage !== "" && this.props.ssprMessage !== undefined ){
            statusPanel = <div className={"alert alert-"+this.props.ssprMessage.alertType}>{this.props.ssprMessage.message}</div>
        }

        return(
            <div className="container mt-4">
                <div className="card rounded-0">
                    <div className="card-header">
                        Forgot your password?
                    </div>
                    <div className="card-body">
                        {statusPanel}
                        <form className="form form-inline" method="post" onSubmit={this.submitForm}>
                            <label className="mr-1">Please enter your email </label>
                            <input type="email" className="form-control" name="email" placeholder="Email"
                                   onChange={this.updateEmail} />
                            <input type="submit" className="btn btn-primary" value="Reset Password"/>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ssprMessage: state.applicationMessage.ssprMessage
});

const mapDispatchToProps = (dispatch, state) => ({
    requestPasswordReset: (email) => dispatch( requestSSPR(email) )
});
export default connect(mapStateToProps, mapDispatchToProps)(RequestSSPR);