import _ from 'lodash'
import React from 'react';
import {connect} from "react-redux";
import {getFullPortfolio} from '../../actions/portfolioActions'
import PortfolioSummary from './PortfolioSummary'
import BuySellShares from './BuySellShares'
import Holdings from './Holdings'
import Loading from '../Loading'
import PortfolioHistory from './PortfolioHistory'
import { Link } from 'react-router-dom';

import {getSession} from '../../actions/authenticationActions'
import {CLEAR_STATUS_MESSAGE} from '../../actions/allActions'

class ViewPortfolioDetails extends  React.Component {

    componentDidMount(){
        this.props.fetchPortfolio( this.props.match.params.id );
    }

    componentDidUpdate(prevProps){
      if( this.props.match.params.id !== prevProps.match.params.id ) {
          this.props.fetchPortfolio( this.props.match.params.id );
          this.props.clearMessage();
      }
    }

    render() {
        let portfolio = this.props.portfolio;
        if( !portfolio || this.props.account === null){
            return <Loading />;
        }

        let ownPortfolio = portfolio.accountId === this.props.account.id;

        const stocks = _.map(portfolio.positions, (position) => {
            return ['stocks', position.stock.id].join('=');
        }).join('&');

        return(
            <div className="container mt-4">
                <h2>
                    Portfolio Overview
                    <Link className="btn btn-sm btn-secondary ml-2" role="button" to={"/pit/details/"+portfolio.pitId} >
                        <i className="fas fa-list-ol"/> View Pit Standings
                    </Link>
                    <Link className="btn btn-sm btn-dark ml-2" role="button" to={"/research/"+portfolio.leagueSeason+"?"+stocks}>
                        <i className="fas fa-flask" /> Research
                    </Link>
                </h2>
                <hr/>
                <div className="row">
                    <div className="col">
                        <PortfolioSummary portfolio={portfolio} accountOwn={ownPortfolio} />
                        { ownPortfolio ?
                            <BuySellShares portfolio={portfolio}/>:<div/>
                        }
                    </div>
                    <div className="col">
                        <PortfolioHistory portfolio={portfolio} accountOwn={ownPortfolio}/>
                        <Holdings portfolio={portfolio} ownPortfolio={ownPortfolio} />
                    </div>
                </div>
                <br/>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    account: state.authentication.account,
    portfolio: state.portfolio.portfolioDetails
});

const mapDispatchToProps = (dispatch, state) => ({
    fetchPortfolio: ( id ) =>
        dispatch( getFullPortfolio( id ) ),
    clearMessage: () =>
        dispatch( {type: CLEAR_STATUS_MESSAGE} ),
    refreshSession: () =>
        dispatch( getSession())
});


export default connect(mapStateToProps, mapDispatchToProps)(ViewPortfolioDetails);
