export function getLeague(leagueSeason) {
    return leagueSeason.split("_")[0]
}
export function getCleanName( leagueSeason ){
    return leagueSeason.replace("_", " ").replace("_", " "); // pretty damn ugly. clean up and make "RREGULAR" regular case
}

export function getLeagueFromLeagueSeason( leagueSeason ){
    return leagueSeason.substring(0,3)
}

export function getIconFromLeagueSeason(leagueSeason) {
    return getIconFromLeague(getLeague(leagueSeason) )
}
export function getIconFromLeague(league){
    let leagueUpper = league.toUpperCase();
    if( leagueUpper === "MLB") {
        return "far fa-baseball-ball"
    }else if( leagueUpper === "NBA" ){
        return "far fa-basketball-ball";
    }else if ( leagueUpper === "NFL"){
        return "far fa-football-ball";
    }else if (leagueUpper === "NHL"){
        return "far fa-hockey-puck";
    }
    return "";
}

export function isLeagueSeasonActive( leagueSeason ){
    let activeSeasons = ["MLB_2018_REGULAR", "NFL_2018_REGULAR" ];
    return activeSeasons.includes(leagueSeason);
}
