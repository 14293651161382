import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import {ACCESS_TOKEN} from "../../utils/constants";
import {OAUTH2_FAIL, OAUTH2_SUCCESS, REDIRECT_PATH_SET} from "../../actions/allActions";
import {connect} from "react-redux";

class OAuth2RedirectHandler extends Component {

    constructor( props) {
        super(props);
        this.test = (e) =>  this.props.onOAuth2Success;
    };


    getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

        var results = regex.exec(this.props.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };


    render() {
        console.log( "render redirectHandler")
        let token = this.getUrlParameter('token');
        const error = this.getUrlParameter('error');
        if(token) {
            localStorage.setItem(ACCESS_TOKEN, token);
            return <Redirect to="/home"/>;
         } else {
            return <Redirect to={this.props.location.pathname+this.props.location.search} />;

        }
    }
}

const mapStateToProps = state => ({
    redirectPath: state.authentication.redirectPath
});
;

const mapDispatchToProps = (dispatch, state) => ({
    onOAuth2Success: value =>
        dispatch({ type: OAUTH2_SUCCESS, key: 'token', value }),
    onOAuth2Fail: value =>
        dispatch({ type: OAUTH2_FAIL, key: 'error', value }),
    setRedirectPath: pathName =>
        dispatch({type: REDIRECT_PATH_SET, path: pathName})

});

export default connect(mapStateToProps, mapDispatchToProps)(OAuth2RedirectHandler);