import React from 'react';
import { Link } from 'react-router-dom';
import '../css/sportspits.css';
import {connect} from "react-redux";
import {logout} from "../actions/authenticationActions";
import {fetchAccountPortfolios} from "../actions/portfolioActions";
import * as LeagueSeason from '../utils/LeagueSeason'
import _ from "lodash";

class TopMenu extends React.Component {

    constructor( props) {
        super(props);
        this.logout = (dispatch) => this.props.onLogout();
        this.state = {isMobile:  window.innerWidth < 768}

        this.collapseNav = _.bind(this.collapseNav, this);
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < 768
            });
        }, false);

    }

    componentDidUpdate(){
        if( this.props.accountPortfolios === null &&  this.props.authentication.isAuthenticated){
            this.props.fetchPortfolios()
        }
    }

    collapseNav(){
        let sp_nav_content = document.getElementById("sp_nav_content");
        if( sp_nav_content ){
            sp_nav_content.classList.remove("show");
        }
    }

    render() {
        let portfolioNavLink = "";


        let authenticated = this.props.authentication.isAuthenticated;
        let researchLink = "";
        if( authenticated ){
            researchLink =
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="researchDropdown" role="button"
                       data-toggle="dropdown">
                        Research
                    </a>
                    <div className="dropdown-menu" aria-labelledby="researchDropdown">
                        <Link to="/research" className="dropdown-item" onClick={ this.collapseNav }>Charts</Link>
                        <Link to="/stocks" className="dropdown-item " onClick={ this.collapseNav }>Schedules</Link>
                    </div>
                </li>
        }

        if (this.props.authentication.isAuthenticated === true && this.props.accountPortfolios!== null) {
            let portfolios = this.props.accountPortfolios;
            let activePortfolio = false;
            let portfolioLinks = "";
            if( portfolios != null ){
                portfolioLinks= portfolios.map(function (portfolio, index) {
                if( !portfolio.active){
                    return "";
                }
                activePortfolio = true;
                return  <tr key={portfolio.id}>
                            <td>
                                <Link to={"/portfolio/view/"+portfolio.id}  className="dropdown-item" onClick={ "this.collapseNav" } >
                                    <span className="mr-2 "><i className={LeagueSeason.getIconFromLeagueSeason(portfolio.leagueSeason)}/></span>
                                    {portfolio.portfolioName}
                                </Link>
                            </td>
                            <td className="text-right">
                                <Link to={"/pit/details/"+portfolio.pitId} className="dropdown-item" onClick={ "this.collapseNav" } >
                                    <span>{portfolio.pitName}</span></Link>
                            </td>
                        </tr>
                        })
            }

            portfolioNavLink =  activePortfolio ? (
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                       data-toggle="dropdown">
                        Portfolios
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <table>
                            <tbody>
                                {portfolioLinks}
                            </tbody>
                        </table>


                    </div>
                </li>
            ) : "";
        }

        let navRight = (
            <Link className="btn my-2 my-sm-0" to="/login" onClick={ this.collapseNav }>
                <i className="fas fa-user"/> Login
            </Link>
        );

        const authentication = this.props.authentication;
        const userDropdownMenuSide = this.state.isMobile ? 'dropdown-menu-left' : 'dropdown-menu-right';
        if (authentication.isAuthenticated && authentication.account != null) {
            navRight = (
                <div className="dropdown  my-2 my-sm-0 ">
                    <a className="nav-link dropdown-toggle p-0" href="#" role="button" id="navbarDropdown" data-toggle="dropdown"  style={{background: "none", color: "#fff"}}>
                        <i className="fas fa-user"/> {authentication.account.username}
                    </a>
                    <div className={"dropdown-menu "+userDropdownMenuSide} aria-labelledby="navbarDropdown">
                        <Link className="dropdown-item pointer" to="/account/settings" onClick={ this.collapseNav }>
                            <i className="fas fa-cogs"/> Account Settings
                        </Link>
                        <a className="dropdown-item pointer" onClick={ this.logout}>
                            <i className="fas fa-sign-out-alt"/> Logout
                        </a>
                    </div>
                </div>
            );
        }

        return (
            <nav className="navbar navbar-expand-md navbar-dark bg-dark navbar-sp-custom" id={"nav-main"}>
                <div className="navbar-brand">
                    <Link className="navbar-brand" to="/">Sports Pits</Link>
                    <span className="label badge badge-success">
                        Beta <i className="fas fa-wrench"/>
                    </span>
                </div>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#sp_nav_content" aria-controls="sp_nav_content" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>
                <div className="navbar-collapse collapse" id="sp_nav_content">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item"   >
                            <Link to="/home" className="nav-link " onClick={ this.collapseNav }  >Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about" className="nav-link" onClick={ this.collapseNav }>About</Link>
                        </li>
                        {researchLink}
                        {portfolioNavLink}
                    </ul>
                    <form className="form-inline my-2 my-lg-0">{navRight}</form>
                </div>
            </nav>
        );
    }

}

const mapStateToProps = state => ({
    authentication: state.authentication,
    accountPortfolios: state.portfolio.accountPortfolios
});

const mapDispatchToProps = dispatch => ({
    onLogout: () => dispatch( logout() ),
    fetchPortfolios: () =>  dispatch( fetchAccountPortfolios() )
});

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);
