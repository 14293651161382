import React from 'react';
import {connect} from "react-redux";
import { Link } from 'react-router-dom';

class About extends  React.Component {

    render() {
        return (
            <div className="container">
                <h1 className="mt-4" style={{display: 'inline-block'}}>FOOTBALL IS BACK!&nbsp;<small className={"text-muted"} style={{display: 'inline-block'}}>(and so is SportsPits)</small></h1>
                <hr/>
                <table className="table table-striped">
                    <tbody>
                    <tr>

                        <td className={"media"}>
                            <i className="fas fa-users fa-4x sp-media-item align-self-center"/>
                            <div className="media-body">
                                <h4>Pits</h4>
                                <p>
                                    Join a <Link to="/pit/join/3">public pit</Link> and trade against the masses &ndash;
                                    Or <Link to="/pit/create">create your own</Link>, and invite your friends.<br/>
                                    Once you're in a pit you can start managing your portfolio.
                                </p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={"media"}>
                            <i className="fas fa-chart-line fa-4x sp-media-item align-self-center"/>
                            <div className="media-body">
                                <h4>Stocks</h4>
                                <p>
                                    Team stocks are derived from <a href={"https://projects.fivethirtyeight.com/2019-nfl-predictions/"} target={"_new"}>FiveThirtyEight's</a> projections. For a detailed account on how this is calculated please refer to this <a href="https://fivethirtyeight.com/features/how-our-nfl-predictions-work/" target={"_new"}>article</a>.
                                </p>
                                <p>
                                    Prices will be updated shortly after each game. If a team outperforms it's projections then a <i>dividend</i> will be distributed to all shareholders. Dividend amounts will vary depending on how well a team performs versus how it's expected (the spread).
                                </p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={"media"}>
                            <i className="fas fa-folder fa-4x sp-media-item align-self-center"/>
                            <div className="media-body">
                                <h4>Portfolios</h4>
                                <p>
                                    You are free to buy or sell as many shares as your budget can afford.<br/>
                                    Shares of teams currently (or very recently) involved in a game cannot be traded.<br/>
                                </p>
                                <p>
                                    Not sure what stocks to buy? Be sure to do some <Link to="/stocks">research</Link>.
                                </p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={"media"}>
                            <i className="fas fa-hand-holding-usd fa-4x sp-media-item align-self-center"/>
                            <div className="media-body">
                                <h4>Payouts</h4>
                                <p>
                                    Payouts will be based on how well a portfolio performed against the pit. Portfolios above
                                    the pit average will gain a proportional amount of their buy-in, while those below will likewise lose a
                                    proportional amount of their buy-in.
                                </p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={"media"}>
                            <i className="fas fa-question fa-4x sp-media-item align-self-center"/>
                            <div className="media-body">
                                <h4>Comments / Questions</h4>
                                <p>
                                    Please feel free to email me directly at <a href={"mailto:alec.burgess@gmail.com"}>alec.burgess@gmail.com</a><br/>
                                    Your feedback is <strong>greatly</strong> appreciated.
                                </p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={"media"}>
                            <i className="fas fa-thumbs-up fa-4x sp-media-item align-self-center"/>
                            <div className="media-body pt-2">
                                <br/>
                                <span className={"h3"}>Good luck out there!</span>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default connect(null, null)(About);