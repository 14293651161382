import axios from 'axios';
import {
    ERROR_MESSAGE,
    FETCH_PIT_DETAILS_FAIL,
    FETCH_PIT_DETAILS_SUCCESS,
    JOIN_PIT,
    JOIN_PIT_SUCCESS,
    JOIN_PIT_FAIL, FETCH_PIT_TO_JOIN, FETCH_PIT_TO_JOIN_SUCCESS, FETCH_PIT_TO_JOIN_FAIL,
    CREATE_PIT, CREATE_PIT_SUCCESS, CREATE_PIT_FAIL
} from "./allActions";
import { FETCH_PITS_AVAILABLE, RECEIVE_PITS_AVAILABLE} from "./allActions";
import {FETCH_PITS_JOINED, RECEIVE_PITS_JOINED} from "./allActions";
import {fetchAccountPortfolios} from "./portfolioActions";
import {getSession} from "./authenticationActions";
import { push } from 'connected-react-router'

export function fetchPitsAvailable() {
    return (dispatch) => {
        dispatch({type: FETCH_PITS_AVAILABLE});
        axios.get('/api/market/availablePits')
            .then(response => {
                if (response.status === 200) {
                    dispatch(receivePitsAvailable(response.data))
                } else {
                    dispatch({type: ERROR_MESSAGE})
                }
            });
    }
}

export function receivePitsAvailable(data) {
    return {type: RECEIVE_PITS_AVAILABLE, pits: data};
}

export function fetchPitToJoin( pitId ){
    return (dispatch) => {
        dispatch( {type: FETCH_PIT_TO_JOIN });
        axios.get('/api/market/pit/summary', {params: {pitId: pitId}})
            .then( response => {
                    if (response.status === 200) {
                        dispatch({type: FETCH_PIT_TO_JOIN_SUCCESS, pit: response.data})
                    }
                    return response;
                }
            ).catch(error => {
            if( error.response != null && error.response.data !== null ) {
                dispatch({type: FETCH_PIT_TO_JOIN_FAIL, errorMessage: error.response.data})
            }else{
                dispatch( {type: ERROR_MESSAGE})
            }
        })
        }

}

// deprecated
//todo: remove
export function fetchPitsJoined() {
    return (dispatch, state) => {
        dispatch({type: FETCH_PITS_JOINED});
        axios.get('/api/market/joinedPits')
            .then(response => {
                if (response.status === 200) {
                    dispatch({type: RECEIVE_PITS_JOINED, pits: response.data})
                } else {
                    dispatch({type: ERROR_MESSAGE})
                }
            });
    };
}

export function joinPit( pitId, portfolioName, pitPassword){

    return( dispatch, state ) => {
        dispatch( {type: JOIN_PIT });
        axios.post( "/api/market/pit/join", {pit: { id: pitId, password: pitPassword}, name: portfolioName } )
            .then( response => {
                if( response.status === 200 ){
                    dispatch( { type: JOIN_PIT_SUCCESS, result: response.data} )
                }
                return response;
            }).then( response =>{
                dispatch( fetchAccountPortfolios() );
                return response;
            }).then( response => {
                if( response.status === 200 ){
                    dispatch( push('/portfolio/view/'+response.data.portfolio.id) )
                }
            }).catch(error => {
              if( error.response != null && error.response.data !== null ) {
                dispatch({type: JOIN_PIT_FAIL, errorMessage: error.response.data})
            }
        })
    }
}

export function fetchPitDetails( pitId ){
    return (dispatch) => {
        axios.get( '/api/market/pit/details', {params:{
                pitId: pitId
            }}).then( response => {
            if( response.status === 200 ){
                dispatch( { type: FETCH_PIT_DETAILS_SUCCESS, pitDetails: response.data} )
            }
        }).catch(error => {
            if( error.response != null && error.response.data !== null ) {
                dispatch({type: FETCH_PIT_DETAILS_FAIL, pitErrorMessage: error.response.data})
            }
        })
    }
}

export function createPit( newPit ){
    return (dispatch) => {
        // dispatch({type: CREATE_PIT})
        axios.post('/api/market/pit/create', newPit)
            .then(response => {
                if (response.status === 200) {
                    dispatch({type: CREATE_PIT_SUCCESS, response: response.data});
                    dispatch( push("/pit/join/"+response.data.pitDetails.id))
                }
            }).catch(error => {
            if (error.response != null && error.response.data !== null) {
                dispatch({type: CREATE_PIT_FAIL, errorResponse: error.response.data})
            } else {
                dispatch({type: ERROR_MESSAGE})
            }
            dispatch( push("/home"))
        })
    }
}
