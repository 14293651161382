import _ from 'lodash';
import dayjs from 'dayjs';
import React from 'react';
import Loading from './Loading';
import {connect} from 'react-redux';
import {fetchLeagueSeasonStockSummaries, fetchStock} from '../actions/stockActions';
import * as txt from '../utils/text';
import * as LeagueSeason from "../utils/LeagueSeason";
import {CLEAR_STOCK, UPDATE_RESEARCH_FIELD} from "../actions/allActions";
import {Link} from "react-router-dom";
import {ArrowChange} from "./util/ArrowChange";
import { Button, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

class StockDetails extends  React.Component {

    constructor(props) {
        super(props);

        this.onStockInput = _.bind(this.onStockInput, this);
        this.onStockClick = _.bind(this.onStockClick, this);
    }

    componentDidMount() {

        if (this.props.match.params.id !== undefined) {
            this.props.fetchStock(this.props.match.params.id);
        } else if (this.props.research.selected_stock !== '') {
            this.props.fetchStock(this.props.research.selected_stock);
        }

        if( this.props.research.league ){
            const leagueSeasons = this.props.leagues[this.props.research.league]
            this.props.updateResearchField('league_seasons', leagueSeasons );
        }


        this.props.fetchStockSummaries(this.props.research.league_season)
    }

    componentDidUpdate(prevProps, prevState) {
        if( this.props.research.league_season !== prevProps.research.league_season ){
            this.props.fetchStockSummaries(this.props.research.league_season)
        }
        // const changed = _.some(['research', 'league_season', 'selected_stock'], (key) => {
        //     return _.get(prevProps.research, key) !== _.get(this.props.research, key);
        // });

        // if( changed) {
        //
        //
        //     fetchStock( this.props.research.selected_stock )
        // }

    }

    onLeagueChange = (e) => {
        const league = e.target.value;
        this.props.updateResearchField('league', league );
        const leagueSeasons = this.props.leagues[league]
        if( leagueSeasons ){
            this.props.updateResearchField('league_seasons', leagueSeasons );
            this.props.updateResearchField('league_season', leagueSeasons[0] );
        }

        this.props.clearStock()
    }

    onSeasonChange = (e) =>{
        const season = e.target.value
        this.props.updateResearchField('league_season', season );
        this.props.fetchStockSummaries( season );
        this.props.clearStock()
    }


    getGameResultClass(game) {
        let resultClass;
        if (game.teamScore > game.adversaryScore) {
            resultClass = 'text-success';
        } else if (game.teamScore === game.adversaryScore) {
            resultClass = 'text-dark';
        } else {
            resultClass = 'text-danger';
        }
        return resultClass;
    }

    getGameResult(game) {
        let resultText;
        if (game.complete) {
            if (game.teamScore > game.adversaryScore) {
                resultText = 'W';
            } else if (game.teamScore === game.adversaryScore) {
                resultText = 'T';
            } else {
                resultText = 'L';
            }
        }

        if (resultText) {
            return <td className={`text-center ${this.getGameResultClass(game)}`}>{resultText}</td>
        }

        return <td></td>
    }

    getPriceOutcome(game){
        if( game.pricePoint != null ) {
            return <td className="text-center">
                <ArrowChange value={game.pricePoint.price - game.pricePoint.pricePre}/>
            </td>
        }
        return <td></td>
    }

    getGameScore(game) {
        if (game.complete) {
            return <td className="text-right">{game.teamScore} - {game.adversaryScore}</td>;
        }
    }

    getGameStartDate(game) {
        if (!game.complete) {
            return <td className="text-right" colSpan="2">{dayjs(game.startDate).format('h:mm a')}</td>;
        }
    }

    getDividendStatus(game){
        if( !game.complete ){
            if( game.spread != undefined ){
                let spreadClass = game.spread > 0 ? 'text-danger' : 'text-success';
                return <td className={"text-right " + spreadClass }>{txt.format(txt.formats.absoluteChange, game.spread)}</td>
            }
            return <td></td>
        } else{

            if( this.props.stock.stockEntry.gameDividendMap != undefined ){
                if( this.props.stock.stockEntry.gameDividendMap[game.id] ){
                    const dividend =  this.props.stock.stockEntry.gameDividendMap[game.id];
                    return <td className={"text-right"}>
                        <i className="far fa-sack-dollar pointer" id={"dividend-"+game.id}></i>
                        <UncontrolledPopover placement="bottom" target={"dividend-"+game.id}>
                            <PopoverHeader>Dividend Distributed</PopoverHeader>
                            <PopoverBody>
                                <p className={" m-3"}>
                                <table className={""}>
                                    <tbody>
                                    <tr><td>Base</td><td >{txt.format(txt.formats.currency, dividend.base) }</td></tr>
                                    <tr><td>Bonus</td><td>{txt.format(txt.formats.currency, dividend.total - dividend.base)} <small>(Projected {game.spread})</small></td></tr>
                                    <tr><th>Total</th><th>{txt.format(txt.formats.currency,dividend.total)}</th></tr>
                                    </tbody>
                                </table>
                                </p>
                            </PopoverBody>
                        </UncontrolledPopover>

                    </td>
                }
            }
            return <td className={"text-right"}></td>
        }
    }

    getHeader() {
        let header;
        let team = _.get(this.props, ['stock', 'stockEntry', 'team']);
        const league = this.props.research.league_season.toUpperCase().replace(/_/g, '-');
        if (team) {
            header = (
                <section>
                    <div className="row">
                    <div className="col-9">
                        <h2>{team.city} {team.name}</h2>
                        <h6 className="text-muted">{league}: {team.shortName}</h6>
                    </div>
                    <div className="col-3">
                        <Link className="btn btn-outline-dark mb-0 float-right" to={"/research/"+this.props.research.league_season+"?stocks="+this.props.stock.stockEntry.id}>
                            <i className="fas fa-chart-line "></i>
                            <br/>
                            Chart
                        </Link>
                    </div>
                    </div>
                    <hr/>
                </section>
            );
        }else {

            header = (
                <section>
                    {/*<h2></h2>*/}
                    <h3 >{league}</h3>
                    <hr/>
                </section>
            )
        }

        return header;
    }



    getTeamSchedule() {
        let teamSchedule;
        let games = _.get(this.props, ['stock', 'stockEntry', 'games']);

        if (games) {
            teamSchedule = (
                <section className="mt-4 mb-4">
                    <h3>Team Schedule</h3>
                    <div className="card schedule mt-3">
                        <div className="card-body">
                            <table className="table table-sm">
                                {
                                    games.map((game, index) => {
                                        const adversary = game.adversary;
                                        if( adversary === null || game.adversaryStock === null ) {
                                            return "";
                                        }

                                        return (
                                            <tr key={game.id}>
                                                <td>{dayjs(game.startDate).format('MMM D')}</td>
                                                <td>{game.home ? 'vs' : '@'}</td>
                                                <td>
                                                    <a href={`/stocks/${game.adversaryStockId}`} title={`${adversary.city} ${adversary.name}`}>
                                                        <span className="d-none d-md-inline">{adversary.city}</span>
                                                        &nbsp;
                                                        <span>{adversary.name}</span>
                                                    </a>
                                                </td>
                                                {this.getGameResult(game)}
                                                {this.getPriceOutcome(game)}
                                                {this.getDividendStatus(game)}
                                                {this.getGameScore(game) || this.getGameStartDate(game)}
                                            </tr>
                                        );
                                    })
                                }
                            </table>
                        </div>
                    </div>
                </section>
            );
        }

        return teamSchedule;
    }

    getAbsolutePriceChange(stock) {
        if (_.isFinite(stock.absolutePriceChange)) {
            return <small className={txt.getRelativeTextClass(stock.absolutePriceChange)}>{txt.format(txt.formats.absoluteChange, stock.absolutePriceChange)}</small>;
        }
    }
    getRelativePriceChange(stock) {
        if (_.isFinite(stock.relativePriceChange)) {
            return <small className={txt.getRelativeTextClass(stock.relativePriceChange)}> ({txt.format(txt.formats.roundedPercent, stock.relativePriceChange)})</small>;
        }
    }

    getStocks() {
        let stocks;
        if (!this.props.stocks.loading) {
            stocks = _.map(_.omit(this.props.stocks.stockSummaries, 'loading'), (stock) => {
                const team = stock.team;
                const name = `${team.city} ${team.name}`;
                return <option key={stock.id} value={stock.team.shortName}>{name}</option>
            });
        }
        return stocks;
    }

    getSelectedStockBadge( stock ){
        if( stock === undefined ){
            return ""
        }
        return (<div className={"mt-1"}>
            {this.getStockBadge( stock )}
            <Link to={"/stocks/"+stock.id} className={"btn btn-outline-secondary btn-sm"}><i className={"far fa-calendar-alt mr-1"}/> View Schedule</Link>
        </div>)
    }

    onStockClick(e) {
        this.props.clearStock();
        document.getElementById("stocks").value='';
    }
    getStockBadge( stock ) {
        const color = `#${stock.team.colorPrimary}`;
        const buttonStyle = {
            backgroundColor: color,
            borderColor: color,
            color: '#fff',
            margin: '3px 3px 3px 0'
        };

        const spanStyle = {
            color: 'inherit',
            float: 'none'
        };

        return (
            <button key={stock.id} data-id={stock.id} className="btn btn-sm" type="button" style={buttonStyle} onClick={this.onStockClick}>
                <span className="close" aria-label="Close" style={spanStyle}>&times;</span> {stock.team.name}
            </button>
        );
    }

    getStockPanel() {
        if( this.props.stock.loading ){
            return  <Loading />
        }

        return (
            <section>
                {this.getHeader()}
                {this.getStockPanelBody()}
            </section>
        )
    }
    onStockInput(e) {
        const code = e.target.value;
        let stock = _.find(this.props.stocks.stockSummaries, (c) => {
            return (c.team.shortName === code);
        });

        if (stock) {
            this.props.fetchStock(stock.id)
        }
    }

    getSelectedStockPanel( stock ){
        if( stock === undefined ){
            return ""
        }
        return (<div className={"mt-1"}>
            {this.getStockBadge( stock )}
        </div>)
    }

    getTotalDividends( stock ){
        return _.sum( _.map(stock.stockDividends, (d) => { return d.total}) )
    }

    getStockPanelBody(){
       const stock = this.props.stock.stockEntry;
       return this.props.stock.stockEntry === undefined ?
            <div class="alert alert-success alert-dismissible fade show" role="alert">
                <div className="d-sm-flex d-md-inline-flex flex-md-row align-items-start">
                    <i className="far fa-calendar-alt fa-4x pr-2 pt-2" />
                    <div className="p-2 mt-3">
                        <h5 className="alert-heading ">Please select a stock</h5>
                    </div>
                </div>
            </div>
            :
            <div ref={this.chartRef} id="chart">

                <div className="row">
                    <div className="col">
                        <div className="card overview price">
                            <div className="card-body">
                                <h3>
                                    <span>{txt.format(txt.formats.currency, stock.currentPrice)}</span>
                                    <small className="text-muted"> USD </small>
                                    {this.getAbsolutePriceChange(stock)}
                                    {this.getRelativePriceChange(stock)}
                                </h3>
                                <small className="text-muted">Closed: {dayjs(stock.closeDate).format('MM-DD-YYYY hh:mm a')}</small>
                                <table className="table table-sm text-muted">
                                    <tbody>
                                    <tr>
                                        <td>High</td>
                                        <td className="text-right">{txt.format(txt.formats.currency, stock.maximumPrice)}</td>
                                    </tr>
                                    <tr>
                                        <td>Low</td>
                                        <td className="text-right">{txt.format(txt.formats.currency, stock.minimumPrice)}</td>
                                    </tr>
                                    <tr>
                                        <td>Prev close</td>
                                        <td className="text-right">{txt.format(txt.formats.currency, stock.previousPrice)}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Dividends</td>
                                        <td className="text-right">{txt.format(txt.formats.currency, this.getTotalDividends( stock ) )}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {this.getTeamSchedule()}
                    </div>
                </div>
            </div>
    }

    render() {

        if (this.props.stocks.loading || this.props.stocks.stockSummaries === undefined ) {
            return <Loading />;
        }

        const stock = this.props.stock.stockEntry;
        let leagues;
        let leagueSeasons = this.props.research.league_seasons;
        let leagueSeasonOptions =_.map(leagueSeasons, (ls) => {
            let isSelected = ls === this.props.research.league_season;
            return <option selected={isSelected}>{ls}</option>
        });

        const stockListTitle = this.props.stocks.loading ? 'Loading...' : 'Type a city, team or stock...';

        let stocks = "";
        let stockPanel = this.getStockPanel()
        stocks = this.getStocks();
        leagues = _.map(Object.keys(this.props.leagues), (league) =>{
            let isSelected = this.props.research.league === league ? 'active' : '';
            return <button value={league} className={"btn btn-outline-dark " + isSelected} onClick={this.onLeagueChange}>
                <i className={LeagueSeason.getIconFromLeague(league)} /><br/>
                {league}</button>
        });

        const stockDetails = this.getSelectedStockPanel( stock );

        return (
            <div className="container mt-4">
                <div className="row">
                    <div className="col-lg-3 col-md-4">
                        <form onSubmit="return false;">
                            <div className="form-group">
                                <label htmlFor="league">League</label><br/>
                                <div className="btn-group btn-group-toggle" data-toggle="buttons" >
                                    {leagues}
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="season">Season</label>
                                <select className="form-control" name="season" onChange={this.onSeasonChange}>{leagueSeasonOptions}</select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="stock">Stock</label>
                                <input type="text" autoComplete={"off"} className="form-control" id="stocks" name="stocks" placeholder={stockListTitle} list="stockList" onInput={this.onStockInput} />
                                <datalist id="stockList" title={stockListTitle}>{stocks}</datalist>
                                {/*<select className="form-control" id="stock" name="stock" placeholder={stockListTitle}  onInput={this.props.onStockInput}>*/}
                                {/*</select>*/}
                                {stockDetails}
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-9 col-md-8">
                    {stockPanel}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    leagues: state.leagues,
    research: state.research,
    stocks: state.stocks,
    stock: state.stock
});

const mapDispatchToProps = (dispatch, state) => ({
    fetchStockSummaries: (leagueSeason) => {dispatch(fetchLeagueSeasonStockSummaries(leagueSeason)) },
    fetchStock: (stockId) =>{ dispatch(fetchStock(stockId)); },
    clearStock: () => {dispatch({type: CLEAR_STOCK})},
    updateResearchField: (field, value) => {
        dispatch( {type: UPDATE_RESEARCH_FIELD, key: field, value})
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(StockDetails);
