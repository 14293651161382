import React from 'react';
import { Link } from 'react-router-dom';

import AvailablePits from './pit/AvailablePits'
import Portfolios from './portfolio/Portfolios'
import {connect} from "react-redux";

class Home extends  React.Component {

    render() {
        if( this.props.authentication.loading  ){
            return <div/>
        }

        let account = this.props.authentication.account;
        if( account === null ){
            return <div/>
        }
        return (
            <div>
                <div className="container">
                    <div className="jumbotron mt-4 pt-3 pb-3">
                        <h2>Welcome {account.firstName},</h2>
                        <hr/>
                        <p className="lead">Thank you for participating in SportsPits! We're currently in the beta
                            phase so please bear with us.<br/>
                            <div className="alert alert-success mt-2 media" role="alert">
                                <i className="fas fa-sack-dollar fa-4x pr-2 pt-2  align-self-center " />
                                <div className="p-2">
                                    <h3 className="alert-heading ">"Stimulus package" for all portfolios!</h3>
                                    <ul>
                                        <li>$50 cash has been added to all portfolios</li>
                                        <li>Transaction fees have been removed</li>
                                        <li>All transaction fees previously collected have been reimbursed</li>
                                    </ul>
                                </div>
                            </div>
                        </p>
                    </div>
                    <Portfolios />
                    <AvailablePits />
                    <br/>
                </div>
                <br/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    // pitsAvailable: state.pits.pitsAvailable,
    authentication: state.authentication
    // account: state.authentication.account
});

const mapDispatchToProps = (dispatch, state) => ({
    // onFetchPitsAvailable =
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);