import React from 'react';
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import * as d3 from "d3";
import {SET_MARKET_TRANSACTION} from "../../actions/allActions";
import * as txt from '../../utils/text'

class Holdings extends React.Component {
    componentDidMount(){
        if( !this.chartDrawn){
            this.pieChart();
        }
    }

    componentDidUpdate(prevProps){
        d3.select("#pie-svg").remove();
        this.pieChart();
    }



    render(){
        let portfolio = this.props.portfolio;
        if( portfolio === undefined || portfolio.loading === true ){
            return <div/>
        }

        let positions = portfolio.positions;
        let ownPortfolio = this.props.ownPortfolio;
        let updateMarketTransaction = this.props.updateMarketTransaction;

        return(
            <div className="card mt-2">
                <div className="card-header">
                    <h5>Holdings</h5>
                </div>
                <div className="card-body">
                    <div id="pie" style={{textAlign: 'center'}}>
                    </div>
                </div>
                <table className="table table-sm">
                    <thead className="thead-light">
                    <tr>
                        <th>Stock</th>
                        <th className="text-right">#</th>
                        <th className="d-none d-md-table-cell text-right">Purchase</th>
                        <th className="text-right">Current</th>
                        <th colSpan="2" className="text-right">Gain</th>
                        <th className="text-right">Value</th>
                        <th className="d-none d-md-table-cell">&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        positions.map( function(position) {
                            return (
                                <tr key={position.id}>
                                    <td>
                                        <Link to={"/stocks/"+position.stock.id}>{position.stock.team.name}</Link>
                                    </td>
                                    <td align="right">{position.shares}</td>
                                    <td align="right"  className="d-none d-md-table-cell">
                                        {txt.format(txt.formats.currency, position.averagePrice)}
                                    </td>
                                    <td align="right">
                                        {txt.format(txt.formats.currency, position.stock.currentPrice)}
                                    </td>
                                    <td align="right">
                                        {position.changePercentage}
                                    </td>
                                    <td align="right">
                                        <span className={txt.getRelativeTextClass( position.gain)}>
                                            {txt.format(txt.formats.absoluteChange,  position.gain)}
                                        </span>
                                    </td>
                                    <td align="right">
                                        {txt.format(txt.formats.currency, position.value)}
                                        <div className="d-block d-md-none">
                                            {ownPortfolio ?
                                                <button className="btn btn-success btn-sm"
                                                        onClick={ () => {
                                                            document.getElementById( "stocks").value=position.stock.team.shortName
                                                            updateMarketTransaction(position.stock.id, "SELL", position.shares)
                                                        }}>Sell</button> : ""
                                            }
                                        </div>
                                    </td>
                                    <td className="d-none d-md-table-cell">
                                        &nbsp;
                                        {ownPortfolio ?
                                            <button className="btn btn-success btn-sm"
                                                    onClick={ () => updateMarketTransaction(position.stock.id, "SELL", position.shares)}>Sell</button> : ""
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }

                    </tbody>
                    <tfoot >
                        <tr>
                            <td>Cash</td>
                            <td/>
                            <td className="d-none d-md-block"/>
                            <td colSpan="3"/>
                            <td align="right">{txt.format(txt.formats.currency, portfolio.cash)}</td>
                            <td className="d-none d-md-block" />
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td/>
                            <td className="d-none d-md-block"/>
                            <td colSpan="3"/>
                            <td align="right">{txt.format(txt.formats.currency, portfolio.totalValue)}</td>
                            <td className="d-none d-md-block" />
                        </tr>
                    </tfoot>
                </table>
            </div>
        )
    }

    pieChart(){
        // todo JSON.parse redundant -- update api
        let data = JSON.parse(this.props.portfolio.holdingJsonArray);

        let width = 284,
            height = 284,
            radius = Math.min(width, height) / 2;

        let arc = d3.arc()
            .outerRadius(radius - 10)
            .innerRadius(0);

        let pie = d3.pie()
            .sort(null)
            .value(function (d) {
                return d.value;
            });


        let svg = d3.select("#pie").append("svg")
            .attr( "id", "pie-svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");


        let g = svg.selectAll(".arc")
            .data(pie(data))
            .enter().append("g")
            .attr("class", "arc");

        g.append("path")
            .attr("d", arc)
            .style("fill", function (d) {
                return '#' + d.data.color;
            });



        g.append("text")
            .style("text-anchor", "middle")
            .attr("transform", function (d) {
                return "translate(" + arc.centroid(d) + ")";
            })
            .attr("dy", ".35em")

            .attr("fill", function(d){
              return "#fff"
            })
            .text(function (d) {
                return d.data.label;
            });

        this.chartDrawn = true;
    }


}
const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch, state) => ({
    updateMarketTransaction: ( stockId, action, shares) =>{
        dispatch( {type: SET_MARKET_TRANSACTION, marketTransaction: { stockId: stockId, action: action, shares: shares} })
    }

});

export default connect(mapStateToProps, mapDispatchToProps)(Holdings);
