import {
    ERROR_MESSAGE,
    FETCH_PORTFOLIO,
    FETCH_PORTFOLIO_SUCCESS,
    FETCH_PORTFOLIO_FAIL,
    FETCH_ACCOUNT_PORTFOLIOS, FETCH_ACCOUNT_PORTFOLIOS_SUCCESS,
} from "./allActions";
import { PROCESS_MARKET_TRANSACTION, PROCESS_MARKET_TRANSACTION_SUCCESS, PROCESS_MARKET_TRANSACTION_FAIL } from "./allActions";
import { UPDATE_PORTFOLIO_NAME, UPDATE_PORTFOLIO_NAME_SUCCESS, UPDATE_PORTFOLIO_NAME_FAIL} from "./allActions";
import {FETCH_PORTFOLIO_HISTORY, FETCH_PORTFOLIO_HISTORY_SUCCESS, FETCH_PORTFOLIO_HISTORY_FAIL} from "./allActions";
import axios from "axios/index";

export function fetchAccountPortfolios(){
    return( dispatch ) => {
        dispatch( {type: FETCH_ACCOUNT_PORTFOLIOS});
        axios.get('/api/account/portfolios').then( response => {
                if( response.status === 200 ){
                    dispatch( {type: FETCH_ACCOUNT_PORTFOLIOS_SUCCESS, portfolios: response.data})
                }
            }
        ).catch( err => {
            dispatch({type: ERROR_MESSAGE, err})
        })
    }
}

export function getFullPortfolio( id ){
    return( dispatch) => {
        dispatch({type: FETCH_PORTFOLIO});
        axios.get('/api/portfolio/details', {
            params: {
                portfolioId: id
            }
        }).then(response => {
            if (response.status === 200) {
                dispatch(fetchPortfolioSuccess(response.data));
                return response
            }
        }).catch(err => {
            if (err.response && err.response.data) {
                dispatch({type: FETCH_PORTFOLIO_FAIL, errorMessage: err.response.data.message})
            } else {
                dispatch({type: ERROR_MESSAGE, err})
            }
        });
    }
}

function fetchPortfolioSuccess( data ){
    return { type: FETCH_PORTFOLIO_SUCCESS, portfolio: data }
}

export function processMarketTransaction( portfolioId,  transaction ){
    return(dispatch) => {
        dispatch({type: PROCESS_MARKET_TRANSACTION});
        axios.post('/api/market/processTransaction',
            {
                portfolioId: portfolioId,
                stockId: transaction.stockId,
                action: transaction.action,
                shares: transaction.shares
            }).then(response => {
            if (response.status === 200) {
                dispatch({type: PROCESS_MARKET_TRANSACTION_SUCCESS, transactionMessage: response.data});
                dispatch( getFullPortfolio( portfolioId) )
            }
            return response;
        }).catch(err => {
            if (err.response != null) {
                dispatch({type: PROCESS_MARKET_TRANSACTION_FAIL, transactionMessage: err.response.data})
            } else {
                dispatch({type: ERROR_MESSAGE, error: err})
            }
        });
    }
}

export function updatePortfolioName( id, newName) {
    return(dispatch) => {
        dispatch({type: UPDATE_PORTFOLIO_NAME});
        axios.post('/api/portfolio/updateName',  {id: id, newName: newName})
            .then(response => {
                if (response.status === 200) {
                    dispatch({type: UPDATE_PORTFOLIO_NAME_SUCCESS,  portfolio: response.data });
                }
                return response;
            }).catch(err => {
            if (err.response != null) {
                dispatch({type: UPDATE_PORTFOLIO_NAME_FAIL, transactionMessage: err.response.data})
            } else {
                console.log( "err");
                console.log( err);
                dispatch({type: ERROR_MESSAGE, error: err})
            }
        });
    }
}

export function fetchPortfolioHistory(){

    return( dispatch ) => {
        dispatch( {type: FETCH_PORTFOLIO_HISTORY});
        axios.get('/api/account/portfolioHistory').then( response => {
                if( response.status === 200 ){
                    dispatch( {type: FETCH_PORTFOLIO_HISTORY_SUCCESS, portfolios: response.data})
                }
            }
        ).catch( err => {
           dispatch({type: FETCH_PORTFOLIO_HISTORY_FAIL })
        })
    }
}
