import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter  } from 'react-router-dom'

import About from "./components/About";
import IndexPage from "./components/IndexPage";
import Login from "./components/Login";
import Home from "./components/Home";
import TopMenu from './components/TopMenu'
import Research from './components/Research'
import RegistrationForm from './components/RegistrationForm'
import ViewPortfolio from './components/portfolio/ViewPortfolioDetails'
import AccountSettings from './components/AccountSettings'
import UpdatePassword from './components/account/UpdatePassword'
import PitSummary from './components/pit/PitSummary'
import StockDetails from './components/StockDetails'
import JoinPit from './components/pit/JoinPit'
import CreatePit from './components/pit/CreatePit'

import { Route } from 'react-router'
import PrivateRoute from './routes/PrivateRoute'
import RequestSSPR from "./components/account/RequestSSPR";
import OAuth2RedirectHandler from "./components/account/OAuth2RedirectHandler";
import {GOOGLE_AUTH_URL_DEV, GOOGLE_AUTH_URL_PROD, GOOGLE_AUTH_URL_PREPROD} from "./utils/constants";

export class App extends Component {

    render() {
        return (
            <div id="application">
                <TopMenu />
                <Route exact path ="/" component={IndexPage} />
                <Route path="/login" component={Login} />
                <Route path="/register" component={RegistrationForm}  />
                <Route path="/about" component={About}  />
                <Route path="" component={OAuth2RedirectHandler} />
                <Route path='/oauth2/google' component={() => {
                    if( window.location.hostname.toLowerCase().indexOf("sportspits") >= 0 ){
                        window.location.href = GOOGLE_AUTH_URL_PROD;
                    }else{
                        if( window.location.port === "5000" ){
                            window.location.href = GOOGLE_AUTH_URL_PREPROD;
                        }else{
                            window.location.href = GOOGLE_AUTH_URL_DEV;
                        }

                    }
                    return null;
                }}/>
                <Route path="/oauth2/redirect" component={OAuth2RedirectHandler} />
                <Route path="/account/requestPasswordReset" component={RequestSSPR}/>
                <Route path="/account/updatePassword/:t?/:accountId?" component={UpdatePassword}/>
                <PrivateRoute path="/stocks/:id?" component={StockDetails} />
                <PrivateRoute path="/research/:leagueSeason?" component={Research} />
                <PrivateRoute path="/home" component={Home} />
                <PrivateRoute path="/pit/join/:id" component={JoinPit} />
                <PrivateRoute path="/portfolio/view/:id" component={ViewPortfolio} />
                <PrivateRoute path="/account/settings" component={AccountSettings} />
                <PrivateRoute path="/pit/details/:id" component={PitSummary} />
                <PrivateRoute path="/pit/create" component={CreatePit} />
                {this.props.children}
                <br/>
            </div>
        );
    }
}


export default  withRouter(connect(
    null
    ,
    null
))(App);
