import {
    GET_SESSION,
    GET_SESSION_FAIL,
    GET_SESSION_SUCCESS,
    LOGIN,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    REDIRECT_PATH_CLEAR,
    REQUEST_SSPR,
    REQUEST_SSPR_FAIL,
    REQUEST_SSPR_SUCCESS,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_FAIL,
    UPDATE_PASSWORD_SUCCESS
} from "./allActions";
import axios from 'axios';
import {push} from 'connected-react-router'
import {ACCESS_TOKEN} from "../utils/constants";


export function getSession() {
    return (dispatch) => {
        dispatch( {type: GET_SESSION });
        axios.get('/api/session')
            .then(response => {
                if (response.status === 200) {
                    dispatch({type: GET_SESSION_SUCCESS, authentication: response.data})
                }
            }).catch(error => {
            console.log("Error getting session");
            console.log(error);
            dispatch({type: GET_SESSION_FAIL})
        })
    }
}

// export function getCurrentUserFromToken() {
//     if(!localStorage.getItem(ACCESS_TOKEN)) {
//         return (dispatch) =>
//             dispatch( {type: OAUTH2_FAIL, error: "No access token set."});
//     }
//
//
//     // return request({
//     //     url: API_BASE_URL + "/user/me",
//     //     method: 'GET'
//     // });
// }

export function login(username, password, rememberMe, redirectPath) {
    return (dispatch ) => {
        dispatch({type: LOGIN});
        axios.post('/api/login', {email: username, password: password, rememberMe: rememberMe } )
            .then(response => {
                if (response.status === 200) {
                    dispatch(loginSuccess(response.data));
                    console.log( response );
                    localStorage.setItem(ACCESS_TOKEN, response.data.accessToken);
                    dispatch( getSession() );
                    // localStorage.setItem(ACCESS_TOKEN, response.headers['x-auth-token']);
                    if( redirectPath === undefined || redirectPath === '' || redirectPath === '/login'){
                        dispatch(push('/home'))
                    }else{
                        dispatch( push(redirectPath) );
                        dispatch({type: REDIRECT_PATH_CLEAR})
                    }
                } else {
                    dispatch(loginFail(response.data))
                }
                return response;
            }).catch(error => {
            console.log( error )

            if (error.response != null && ( error.response.status === 401 || error.response.status === 403) ) {
                dispatch(loginFail(error.response.data))
            } else {
                dispatch(push('/'))
            }
        });
    }
}


export function loginSuccess( data ){
    return {type: LOGIN_SUCCESS, authentication: data}
}

export function loginFail( data ){
    return {type: LOGIN_FAIL, response: data}
}

export function logout() {
    localStorage.removeItem(ACCESS_TOKEN);
    return (dispatch) =>
        axios.post('/api/logout')
            .then(response => {
                dispatch( {type: LOGOUT_SUCCESS});
                return response;
            }).then(() => {
            dispatch(push('/'));
        }).then(() => {
            localStorage.removeItem('auth-token');
        }).then(() => {
            dispatch( getSession() );
        });
}

export function requestSSPR( email ) {
    return (dispatch) => {
        dispatch({type: REQUEST_SSPR});
        axios.post('/api/account/resetPassword', email,
            {headers: {"Content-Type": "text/plain"}})
            .then(response => {
                if (response.status === 200) {
                    dispatch({type: REQUEST_SSPR_SUCCESS, response: response.data})
                }
                return response;
            }).then(() => {
            dispatch(push("/"))
        }).catch(error => {
            dispatch({type: REQUEST_SSPR_FAIL, response: error.response.data})
        });
    }
}

export function changePassword( newPassword, confirmPassword, currentPassword, token, accountId ){
    return( dispatch ) => {
        dispatch( {type: UPDATE_PASSWORD });
        let payload = {accountId: accountId, token: token, currentPassword: currentPassword, newPassword: newPassword, newPasswordConfirm: confirmPassword };
        axios.post( '/api/account/changePassword', payload )
            .then( response => {
                if( response.status === 200 ){
                    dispatch({type: UPDATE_PASSWORD_SUCCESS, response: response.data})
                }
                return response;
            }).catch(error => {
            dispatch({type: UPDATE_PASSWORD_FAIL, response: error.response.data })
        });
    }
}


