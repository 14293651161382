import {
    FETCH_PIT_DETAILS_SUCCESS,
    FETCH_PITS_AVAILABLE,
    RECEIVE_PITS_AVAILABLE,
    FETCH_PITS_AVAILABLE_FAIL,
    RECEIVE_PITS_JOINED,
    FETCH_PIT_TO_JOIN,
    FETCH_PIT_TO_JOIN_SUCCESS,
    JOIN_PIT_SUCCESS,
    JOIN_PIT_FAIL,
    FETCH_PITS_JOINED,
    PITS_JOINED_FAIL, CREATE_PIT_SUCCESS
} from '../actions/allActions';

const initialState = {
    fetchingPitsAvailable: false,
    pitsAvailable: null,
    fetchingJoinedPits: false,
    joinedPits: null,
    fetchingPitDetails: false,
    pitDetails: null,
    // todo: Could probably piggyback off pits available, but this is easier for now
    fetchingPitToJoin: false,
    pitToJoin: null,
    responseMessage: "",
    createPitResponse: null
};

export default function pits(state = initialState, action) {
    switch (action.type) {
        case FETCH_PITS_AVAILABLE:
            return{
                ...state,
                fetchingPitsAvailable: true,
                pitsAvailable: null
            } ;
        case RECEIVE_PITS_AVAILABLE:
            return {
                ...state,
                pitsAvailable: action.pits,
                fetchingPitsAvailable: false
            };
        case FETCH_PITS_AVAILABLE_FAIL:
            return {
                ...state,
                fetchingPitsAvailable: false
            };
        case FETCH_PITS_JOINED:
            return{
                ...state,
                fetchingJoinedPits: true
            };
        case RECEIVE_PITS_JOINED:
            return {
                ...state,
                joinedPits: action.pits,
                fetchingJoinedPits: false
            };
        case  PITS_JOINED_FAIL:
                return {
                    ...state,
                    fetchingJoinedPits: false
                };
        case FETCH_PIT_DETAILS_SUCCESS:
            return {
                ...state,
                pitDetails: action.pitDetails
            };
        case FETCH_PIT_TO_JOIN:
            return {
                ...state,
                fetchingPitToJoin: true
            };
        case FETCH_PIT_TO_JOIN_SUCCESS:
            return {
                ...state,
                pitToJoin: action.pit,
                fetchingPitToJoin: false
            };
        case JOIN_PIT_SUCCESS:
            return{
                ...state,
                joinedPits: [],
                pitsAvailable: []
            };
        case JOIN_PIT_FAIL:
            return {
                ...state,
                responseMessage: action.errorMessage
            };
        case CREATE_PIT_SUCCESS:
            return {
                ...state,
                createPitResponse: action.response
            };
        default:
            return state;
    }
}