import {REQUEST_SSPR_SUCCESS, REQUEST_SSPR_FAIL, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAIL} from "../actions/allActions";

const initialState = {
    ssprMessage: "",
    passwordMessage: ""
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case REQUEST_SSPR_SUCCESS:
            return {
                ...state,
                ssprMessage: action.response
            };
        case REQUEST_SSPR_FAIL:
                return {
                    ...state,
                    ssprMessage: action.response
                };
        case UPDATE_PASSWORD_SUCCESS:
                return {
                    ...state,
                    passwordMessage: action.response
                };
        case UPDATE_PASSWORD_FAIL:
            return {
                ...state,
                passwordMessage: action.response
            };
        default:
            return state;
    }
}

