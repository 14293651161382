import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    UPDATE_FIELD_AUTH,
    REDIRECT_PATH_SET,
    REDIRECT_PATH_CLEAR,
    UPDATE_PORTFOLIO_NAME_SUCCESS, OAUTH2_SUCCESS
} from '../actions/allActions'
import {GET_SESSION, GET_SESSION_SUCCESS, GET_SESSION_FAIL } from '../actions/allActions'
import { REGISTER_SUCCESS} from "../actions/allActions";
import {ACCOUNT_UPDATED } from "../actions/allActions";

import {ERROR_MESSAGE} from "../actions/allActions";

const initialState = {
    isAuthenticated: false,
    username: '',
    password: '',
    errorMessage: null,
    account: null,
    redirectPath: '',
    loading: true,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                errorMessage: null,
                loading: true
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                password: '',
                username: '',
                loading: false,
                errorMessage: null
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                account: '',
                errorMessage: action.response
            };
        case OAUTH2_SUCCESS:
            return{
                ...state,
                isAuthenticated: true,
                loading: false,
            }
        case GET_SESSION:
            return {
                ...state,
                loading: true
            };
        case GET_SESSION_SUCCESS:
            return {
                ...state,
                isAuthenticated: action.authentication.authenticated || false,
                username: action.authentication.userName,
                account: action.authentication.account,
                errorMessage: '',
                loading: false
            };
        case GET_SESSION_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                username: '',
                account: '',
                loading: false
            };
        case ACCOUNT_UPDATED:
            return {
                ...state,
                account: {
                    ...state.account,
                    email: action.accountSettings.email,
                    emailNotifications: action.accountSettings.notifications
                }
            };
        case ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.message
            };
        //    todo: remove / rework
        case UPDATE_FIELD_AUTH:
            return { ...state, [action.key]: action.value };
        case REGISTER_SUCCESS:
            return {
                ...state,
                isAuthenticated: action.authentication.authenticated,
                username: action.authentication.userName,
                account: action.authentication.account,
                errorMessage: null
            };
        case REDIRECT_PATH_SET:
            return {
                ...state,
                redirectPath: action.path
            };
        case REDIRECT_PATH_CLEAR:
            return {
                ...state,
                redirectPath: ''
            };
        default:
            return state;
    }
}
