import React from 'react';

class Loading extends React.Component {

    componentWillMount() {
        const me = this;
        const wait = me.props.wait;
        if (wait) {
            const start = Date.now();
            requestAnimationFrame(function frame() {
                const elapsed = Date.now() - start;
                if (elapsed < wait) {
                    return requestAnimationFrame(frame);
                }
                me.setState({waited: true});
            });
        }
    }

    render() {
        if (this.props.wait && !this.props.waited) {
            return null;
        }

        const icon = <i className="fas fa-sync fa-spin text-muted"></i>;
        if (this.props.inline) {
            return <span>{icon}</span>
        }
        let style = {};
        if (this.props.center) {
            style = {
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                left: 0,
                position: 'absolute',
                right: 0,
                // TODO hack for top-nav, is something nicer possible?
                top: '30px'
            };
        }

        return <div className="fa-3x" style={style}>{icon}</div>
    }

}

Loading.defaultProps = {
    center: true,
    inline: false,
    wait: 0
};

export default Loading;
