import {REGISTER, REGISTER_SUCCESS, REGISTER_FAIL} from "../actions/allActions";


const initialState = {
    errorMessage: null,
    loading: true
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                errorMessage: ''
            };
        case REGISTER:
            return{
                ...state,
                loading: true
            };
        case REGISTER_FAIL:
            return {
                ...state,
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
}
