import axios from 'axios';
import { routerMiddleware, connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { ConnectedRouter } from 'connected-react-router'
import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk';
import {getSession} from './actions/authenticationActions';

import {App} from './App'
import rootReducer from './combinedReducers'

import {unregister, register} from "./registerServiceWorker";
import {ACCESS_TOKEN} from "./utils/constants";



const history = createBrowserHistory();

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Hot reloading
if (module.hot) {
    // Reload components
    module.hot.accept('./App', () => {
        render()
    });

    // Reload reducers
    module.hot.accept('./combinedReducers', () => {
        store.replaceReducer(connectRouter(history)(rootReducer))
    })
}


const store = createStore(
    connectRouter(history)(rootReducer),
    composeEnhancer(
        applyMiddleware(
            routerMiddleware(history),
            thunk
        ),
    ),
);


// Add a request interceptor
axios.defaults.baseURL =  ( window.location.hostname.toLowerCase().indexOf("sportspits") >= 0 ) ? 'https://api.sportspits.com' : 'http://localhost:8080';
axios.interceptors.request.use(function (config) {
    var token = localStorage.getItem(ACCESS_TOKEN );

    if (token) {
        config.headers['x-auth-token'] = token;
    }
    config.timeout = 50000;
    return config;
}, function (error) {
    console.log( error.response);
    if (error.response.status === 403) {
        localStorage.removeItem(ACCESS_TOKEN);
        //todo:? onUnauthenticated();
    }
    return Promise.reject(error);
});

const render = () => {
    ReactDOM.render(
        <AppContainer>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </Provider>
        </AppContainer>,
        document.getElementById('root')
    )
};
unregister();

store.dispatch(getSession());
render();
