import React from 'react';
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import { ACCOUNT_UPDATED} from "../actions/allActions";
import axios from "axios/index";

class AccountSettings extends  React.Component {
    state = {
        email: "",
        emailNotifications: "",
        updateMessage: null
    };

    constructor( props) {
        super(props);

        this.state.email = this.props.account.email;
        this.state.emailNotifications = this.props.account.emailNotifications;

        this.accountUpdated = this.props.accountUpdated;

    }

    render() {
        let updateMessage = this.state.updateMessage !== null ?
            <div className={"alert alert-"+this.state.updateMessage.alertType+" alert-dismissible fade show"} role="alert" >
            {this.state.updateMessage.message}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div> : "";
        return (
            <div className="container mt-4">
                <h2>Account Details</h2>
                <hr/>
                {updateMessage}
                <div className="card">
                    <h5 className="card-header">General</h5>
                    <div className="card-body">
                        <form className="form form-horizontal"  method="post"
                              onSubmit={ (e) =>{e.preventDefault(); this.updateAccount(this.state.email, this.state.emailNotifications)} } >
                            <div className="form-group row">
                                <label htmlFor="username" className="col-sm-2 col-form-label">Username</label>
                                <div className="col-sm-10">
                                    <span className="form-control-plaintext" id="username">{this.props.account.username}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="password" className="col-sm-2 col-form-label">Password</label>
                                <div className="col-sm-10">
                                    <Link className="form-control-plaintext" id="password"
                                          to="/account/updatePassword">Update Password</Link>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Email</label>
                                <div className="col-sm-10">
                                    <input type="email" className="form-control" id="inputEmail3" name="email"
                                           value={this.state.email}  onChange={this.handleInputChange}/>
                                </div>
                            </div>
                            <fieldset className="form-group">
                                <div className="row">
                                    <legend className="col-form-label col-sm-2 pt-0">Notifications</legend>
                                    <div className="col-sm-10">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" checked={""+this.state.emailNotifications === "true"}
                                                    onChange={this.handleInputChange} id="emailUpdatesYes" name="emailNotifications" value="true"/>
                                            <label className="form-check-label" htmlFor="emailUpdatesYes">
                                                Yes, send email updates.
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" id="emailUpdatesNo" onChange={this.handleInputChange}
                                                   checked={""+this.state.emailNotifications === "false"} name="emailNotifications" value="false"/>
                                            <label className="form-check-label" htmlFor="emailUpdatesNo">
                                                No, do not send email updates.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label"/>
                                <div className="col-sm-10">
                                    <input className="btn btn-primary" type="submit" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
    handleInputChange = (e) => {
        let value = e.target.value;
        let inputName = e.target.name;
        this.setState({[inputName]: value});
    };

    updateAccount = (email, notifications ) =>{
        axios.post('/api/account/settings', {
            email: email,
            notifications: notifications
        })
            .then( response => {
                if(response.status === 200){
                    this.accountUpdated( email, notifications )
                }
                return response;
            }).then( response => {
            this.setState({updateMessage:  response.data })
        }).catch(error => {
            console.log( "Error getting session");
            console.log( error.response );

            if( error.response !== null &&  error.response.data !== null ){
                this.setState({updateMessage:  error.response.data })
            }else{
                alert( "Error updating settings. Please Refresh and try again.")
            }

            // dispatch( {type: GET_SESSION_FAIL })
        })
    }
}


const mapStateToProps = state => ({
    account: state.authentication.account,
});



const mapDispatchToProps = (dispatch, state) => ({
    accountUpdated: ( email, notifications) => {
        dispatch( {type: ACCOUNT_UPDATED, accountSettings: { email: email, notifications: notifications}})
    }

    // dispatch( updateAccountDetails( id ) )
});


export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);