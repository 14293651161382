import axios from "axios/index";
import {push} from "connected-react-router";

import {REGISTER, REGISTER_SUCCESS, REGISTER_FAIL} from "./allActions";
import {ERROR_MESSAGE} from "./allActions";
import {ACCESS_TOKEN} from "../utils/constants";

export function register(firstName, lastName, username, email, password, passwordVerify ) {
        return (dispatch) =>{
            dispatch({type: REGISTER});
            axios.post('/api/register', {
                firstName: firstName,
                lastName: lastName,
                username: username.trim(),
                email: email,
                password: password,
                passwordVerify: passwordVerify
            }).then( response => {
                if (response.status === 200) {
                    console.log("register success!");
                    dispatch( registerSuccess( response.data) );
                    localStorage.setItem(ACCESS_TOKEN, response.headers['x-auth-token']);
                }
                return response;
            }).then( response =>{
                    if (response.status === 200) {
                        dispatch(push('/home'))
                    }
                    return response;
            }).catch( err => {
                    if( err.response.data ){
                        dispatch({type: REGISTER_FAIL, errorMessage: err.response.data.message})
                    }else{
                        dispatch({type: ERROR_MESSAGE, err })
                    }
            });
        }
}

export function registerSuccess( data ){
    return { type: REGISTER_SUCCESS, authentication: data }
}
