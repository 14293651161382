import * as d3 from 'd3'
import React from 'react';
import _ from 'lodash'
import {connect} from "react-redux";

const $ = window.$;

function drawMultiLineChart(container, portfolio, pitHx, accountOwn ) {

  let data = portfolio.portfolioHx;

  let portfolioName =  portfolio.portfolioName;

  var width = container.clientWidth;
  var height = Math.max(window.innerHeight * .2, 300);

  var parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S");

  var svg = d3.select(container)
  .append('svg')
  .attr('width', width)
  .attr('height', height);

  var margin = {top: 30, right: 30, bottom: 30, left: 30};
  var chartWidth = width - margin.left - margin.right;
  var chartHeight = height - margin.top - margin.bottom;

  var g = svg.append('g')
  .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');


  var formatNumber  = d3.format(".3f")

  var x = d3.scaleTime().rangeRound([0, chartWidth]);

  var dates = data.map( function(d) { return parseTime(d.snapshotDate) });
  var pitDates = pitHx.map( function(d) { return parseTime(d.snapshotDate)});

  x.domain(d3.extent(dates.concat(pitDates)));


  var y = d3.scaleLinear().range([chartHeight, 0 ]);
  y.domain([
    d3.min( data.concat(pitHx), function(d){
      return d.total
    }) * 0.98,
    d3.max( data.concat(pitHx), function(d){
      return d.total
    }) * 1.02
  ])


  var line = d3.line()
  .x(function (d) { return x(parseTime(d.snapshotDate))})
  .y(function (d) { return y(formatNumber(d.total))})
  .curve(d3.curveLinear)




  g.append('g')
  .attr('class', 'axis x')
  .attr('transform', 'translate(0,' + chartHeight + ')')
      // .call(d3.axisBottom(x).ticks(10))
  .call(d3.axisBottom(x).ticks(d3.timeWeek.every(1)));


  g.append('g')
  .attr('class', 'axis y')
  .call(d3.axisLeft(y))
  .append('text')
  .attr('transform', 'rotate(-90)')
  .attr('y', 7)
  .attr('dy', '0.71em')
  .attr('fill', '#000')
  .text("Value");

  var value = g.selectAll('.value')
  .data(prepareLines(data, pitHx ))
  .enter().append('g')
  .attr('class', 'value');

  value.append('path')
  .attr('class', 'line')
  .attr('d', function (d) {
     return line(d.values); })
  .attr('fill', 'none')
  .attr('stroke', d => { return d.color} )
  .attr( 'stroke-dasharray', d => { return d.strokeDasharray})
  .attr('stroke-width', "1");

  value.append('text')
      .datum(function (d) { return {label: d.label, value: _.last(d.values)} })
      .attr('transform', function (d) { return 'translate(' + x(parseTime(d.value.snapshotDate)) + ',' + y(d.value.total) + ')'; })
      .attr('x', 3.15)
      .attr('dy', '0.35em')
      .style('font', '10px sans-serif')
      .text(function (d) { return d.label; });

  function prepareLines(portfolioHx, pitHx ) {
    const shortName = portfolioName.length < 3 ? portfolioName : portfolioName.substr(0, 3);
    let portfolioHxLine = {
      color: '#333',
      label: accountOwn ? 'You' : shortName.toUpperCase(),
      values: portfolioHx
    }

    let pitHxLine = {
      color: '#393',
      label: 'Pit',
      values: pitHx,
      strokeDasharray: '3,3'
    }

    return [portfolioHxLine, pitHxLine ]
  }
}

class PortfolioHistory extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.ownPortfolio = props.accountOwn
  }

  render(){
    return <div className="card mt-2">
    <div className="card-header">
    <h5>History</h5>
    </div>
    <div className="card-body">
    <div ref={this.chartRef} id="chart" />
    </div>
    </div>
  }

  renderChart() {
    const chart = this.chartRef.current;
    chart.innerHTML = '';
    drawMultiLineChart(chart, this.props.portfolio, this.props.portfolio.pitHx, this.props.accountOwn);
  }

  onWindowResize() {
      this.renderChart();
  }

  componentWillUnmount() {
      window.removeEventListener('resize', this.debouncedWindowResizeListener);
  }

componentDidUpdate(prevProps, prevState) {
  if( prevProps.portfolio.id !== this.props.portfolio.id ){
    this.renderChart();
  }


}
  componentDidMount() {
    this.renderChart();
  }
}


const mapStateToProps = state => ({
  // portfolio: state.portfolio.portfolioDetails
});

export default connect(mapStateToProps)(PortfolioHistory);
