import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import {REDIRECT_PATH_SET} from "../actions/allActions";
import Loading from '../components/Loading';

const PrivateRoute = ({ component: Component, auth: auth,  pathName: pathName, setRedirectPath: setRedirectPath, ...rest }) => (
    <Route
        {...rest}
        render={ props => {
            if (auth.loading === true) {
                return <Loading />
            }
            if (auth.isAuthenticated === true) {
                return <Component {...props} />
            } else {
                setRedirectPath( pathName );
                return <Redirect to="/login"/>
            }
        }}
    />
);

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
    setRedirectPath: pathName =>
        dispatch({type: REDIRECT_PATH_SET, path: pathName})
});

function mapStateToProps(state){
    return {
        auth: state.authentication,
        pathName: state.router.location.pathname
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));
