import * as txt from "../../utils/text";
import React from 'react';
import { Link } from 'react-router-dom';
import * as LeagueSeason from "../../utils/LeagueSeason";
import {format} from "../../utils/text";

export class ArrowChange extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let value = this.props.value;
        let icon, className;

        if( value == 0 ){
            icon = "";
            className = ""
        }if( value > 0) {
            icon = "fas fa-caret-up";
            className = "text-success";
        } else{
            icon = "fas fa-caret-down";
            className = "text-danger";
        }

        return <span className={className}><i className={icon}></i> {txt.format(txt.formats.absoluteChange, value) }</span>
    }
}
