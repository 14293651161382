import * as txt from "../../utils/text";
import React from 'react';
import { Link } from 'react-router-dom';
import * as LeagueSeason from "../../utils/LeagueSeason";

export class PortfolioCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let portfolio = this.props.portfolio;
        return (
            <div className="col-sm-12 col-md-4 p-2">
                <div className="card mb-2" key={portfolio.id}>
                    <div className="card-header">
                        <h4>{portfolio.portfolioName}</h4>
                    </div>
                    <ul className="list-group">
                        <li className="list-group-item d-flex align-items-center">
                            <i className={LeagueSeason.getIconFromLeagueSeason(portfolio.leagueSeason) + " mr-2" }/> {portfolio.pitName}
                        </li>
                        <li className="list-group-item d-flex">
                            Rank {portfolio.pitRanking}/{portfolio.pitSize}
                        </li>
                        <li className={"list-group-item d-flex "+ txt.getRelativeTextClass(portfolio.pitPercentGain)} >
                            {portfolio.pitPercentGain}%
                        </li>
                        <li className="list-group-item  ">
                            <Link className="btn btn-sm btn btn-dark mt-sm-2"
                                  to={"/portfolio/view/" + portfolio.id}><i className="fas fa-folder"></i> Manage Portfolio</Link>

                            <Link to={"/pit/details/"+portfolio.pitId} className="btn btn-sm btn-outline-dark ml-sm-1 ml-md-2 mt-sm-2 ">
                                <i className="fas fa-list-ol"/> Pit Standings
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}
