export const ERROR_MESSAGE = "sportspits/ERROR_MESSAGE";
export const ERROR_MESSAGE_UNLOADED = "sportspits/ERROR_MESSAGE_UNLOADED";

export const REDIRECT_PATH_SET = "sportspits/SET_REDIRECT_PATH";
export const REDIRECT_PATH_CLEAR = "sportspits/CLEAR_REDIRECT_PATH";

export const UPDATE_FIELD_AUTH = 'authentication/FIELD_CHANGE';

export const LOGIN = 'authentication/LOGIN';
export const LOGIN_SUCCESS = 'authentication/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'authentication/LOGIN_FAIL';
export const LOGIN_PAGE_UNLOADED = 'authentication/LOGIN_UNLOADED';

export const LOGOUT = 'authentication/LOGOUT';
export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'authentication/LOGOUT_FAIL';

export const OAUTH2_SUCCESS = 'authentication/OAUTH_SUCCESS';
export const OAUTH2_FAIL = 'authentication/OAUTH_FAIL';

export const GET_SESSION = 'authentication/GET_SESSION';
export const GET_SESSION_SUCCESS = 'authentication/GET_SESSION_SUCCESS';
export const GET_SESSION_FAIL = 'authentication/GET_SESSION_FAIL';

export const REGISTER = 'account/REGISTER';
export const REGISTER_SUCCESS = 'account/REGISTER_SUCCESS';
export const REGISTER_FAIL = 'account/REGISTER_ERROR';

export const ACCOUNT_UPDATED = 'account/UPDATED';

export const REQUEST_SSPR = 'account/SSPR';
export const REQUEST_SSPR_SUCCESS = 'account/SSPR_SUCCESS';
export const REQUEST_SSPR_FAIL = 'account/SSPR_FAIL';

export const FETCH_PITS_AVAILABLE = 'pits/FETCH_PITS_AVAILABLE';
export const FETCH_PITS_AVAILABLE_FAIL = 'pits/FETCH_PITS_AVAILABLE_FAIL';
export const RECEIVE_PITS_AVAILABLE = 'pits/RECEIVE_PITS_AVAILABLE';

export const FETCH_PIT_TO_JOIN = "pits/FETCH_PIT_TO_JOIN";
export const FETCH_PIT_TO_JOIN_SUCCESS = "pits/FETCH_PIT_TO_JOIN_SUCCESS";
export const FETCH_PIT_TO_JOIN_FAIL = "pits/FETCH_PIT_TO_JOIN_FAIL";

export const FETCH_PITS_JOINED= 'pits/FETCH_PITS_JOINED';
export const RECEIVE_PITS_JOINED= 'pits/RECEIVE_PITS_JOINED';
export const PITS_JOINED_FAIL= 'pits/FAIL_PITS_JOINED';

export const FETCH_PIT_DETAILS= 'pits/FETCH_DETAILS';
export const FETCH_PIT_DETAILS_SUCCESS = 'pits/FETCH_DETAILS_SUCCESS';
export const FETCH_PIT_DETAILS_FAIL = 'pits/FETCH_DETAILS_FAIL:';

export const JOIN_PIT = '/pit/JOIN';
export const JOIN_PIT_SUCCESS = '/pit/JOIN_SUCCESS';
export const JOIN_PIT_FAIL = '/pit/JOIN_FAIL';

export const CREATE_PIT = 'pits/CREATE';
export const CREATE_PIT_SUCCESS = 'pits/CREATE_SUCCESS';
export const CREATE_PIT_FAIL = 'pits/CREATE_FAIL';

export const FETCH_PORTFOLIO = 'portfolios/FETCH_PORTFOLIO';
export const FETCH_PORTFOLIO_SUCCESS = 'portfolios/FETCH_PORTFOLIO_SUCCESS';
export const FETCH_PORTFOLIO_FAIL  = 'portfolios/FETCH_PORTFOLIO_FAIL';

export const FETCH_ACCOUNT_PORTFOLIOS  = 'portfolios/FETCH_ACCOUNT_PORTFOLIOS';
export const FETCH_ACCOUNT_PORTFOLIOS_SUCCESS  = 'portfolios/FETCH_ACCOUNT_PORTFOLIOS_SUCCESS';
export const FETCH_ACCOUNT_PORTFOLIOS_FAIL  = 'portfolios/FETCH_ACCOUNT_PORTFOLIOS_FAIL';

//Decided to bundle history with portfolio details. levaing for now
export const FETCH_PORTFOLIO_HISTORY = 'portfolios/FETCH_PORTFOLIO_HISTORY';
export const FETCH_PORTFOLIO_HISTORY_SUCCESS = 'portfolios/FETCH_PORTFOLIO_HISTORY_SUCCESS';
export const FETCH_PORTFOLIO_HISTORY_FAIL = 'portfolios/FETCH_PORTFOLIO_HISTORY_FAIL';

export const FETCH_STOCK = 'stocks/FETCH_STOCK';
export const CLEAR_STOCK = 'stocks/CLEAR_STOCK';
export const FETCH_STOCK_SUCCESS = 'stocks/FETCH_STOCK_SUCCESS';
export const FETCH_STOCK_ERROR = 'stocks/FETCH_STOCK_ERROR';

export const FETCH_STOCKS_AVAILABLE = 'stocks/FETCH_AVAILABLE';
export const FETCH_STOCKS_AVAILABLE_SUCCESS  = 'stocks/FETCH_AVAILABLE_SUCCESS';
export const FETCH_STOCKS_AVAILABLE_FAIL= 'stocks/FETCH_AVAILABLE_FAIL';

export const UPDATE_MARKET_TRANSACTION_FIELD = 'market/UPDATE_TRANSACTION_FIELD';
export const SET_MARKET_TRANSACTION = 'market/SET_TRANSACTION'; // Sell button in holdings

export const PROCESS_MARKET_TRANSACTION = 'market/PROCESS_TRANSACTION';
export const PROCESS_MARKET_TRANSACTION_SUCCESS = 'market/PROCESS_TRANSACTION_SUCCESS';
export const PROCESS_MARKET_TRANSACTION_FAIL = 'market/PROCESS_TRANSACTION_FAIL';

export const CLEAR_STATUS_MESSAGE = 'market/CLEAR_STATUS_MESSAGE';


export const UPDATE_RESEARCH_FIELD = 'research/UPDATE_RESEARCH_FIELD';

export const FETCH_LEAGUES = '/leagues/FETCH_LEAGUES';
export const FETCH_LEAGUES_SUCCESS = '/leagues/FETCH_LEAGUES_SUCCESS';
export const FETCH_LEAGUE_SEASON_STOCKS = '/leagues/FETCH_LEAGUE_SEASON_STOCKS';
export const FETCH_LEAGUE_SEASON_STOCKS_SUCCESS = '/leagues/FETCH_LEAGUE_SEASON_STOCKS_SUCCESS';

export const FETCH_LEAGUE_SEASON_STOCK_SUMMARIES = '/leagues/FETCH_LEAGUE_SEASON_STOCK_SUMMARIES';
export const FETCH_LEAGUE_SEASON_STOCK_SUMMARIES_SUCCESS = '/leagues/FETCH_LEAGUE_SEASON_STOCK_SUMMARIES_SUCCESS';
export const FETCH_LEAGUE_SEASON_STOCK_SUMMARIES_FAIL = '/leagues/FETCH_LEAGUE_SEASON_STOCK_SUMMARIES_FAIL';

export const UPDATE_PASSWORD = "account/UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "account/UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "account/UPDATE_PASSWORD_FAIL";

export const UPDATE_PORTFOLIO_NAME = "portfolio/UPDATE_NAME";
export const UPDATE_PORTFOLIO_NAME_SUCCESS = "portfolio/UPDATE_NAME_SUCCESS";
export const UPDATE_PORTFOLIO_NAME_FAIL = "portfolio/UPDATE_NAME_FAIL";
